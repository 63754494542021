export default Object.freeze([
  {
    label: intl.t('configuration.extra-attributes.list.activity'),
    value: 'campaign',
  },
  {
    label: intl.t('configuration.extra-attributes.list.address'),
    value: 'address',
  },
  {
    label: intl.t('configuration.extra-attributes.list.text'),
    value: 'text',
  },
  {
    label: intl.t('configuration.extra-attributes.list.html'),
    value: 'html',
  },
  {
    label: intl.t('configuration.extra-attributes.list.image'),
    value: 'image',
  },
  {
    label: intl.t('configuration.extra-attributes.list.url'),
    value: 'url',
  },
  {
    label: intl.t('configuration.extra-attributes.list.form'),
    value: 'form',
  },
  {
    label: intl.t('configuration.extra-attributes.list.gift-card-client'),
    value: 'gift_card',
  },
  {
    label: intl.t('configuration.extra-attributes.list.gift-card-admin'),
    value: 'gift_card_admin',
  },
]);
