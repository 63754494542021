export default [
  {
    new_folder: 'New folder',
    'campaign-report.estimated-cost': 'Estimated cost',
    'campaign-report.final-cost': 'Final cost',
    'campaign-report.final-cost-in-estimated': 'Within bugdet',
    'campaign-report.final-cost-over-estimated': 'Over budget',
    kpi: 'Kpi',
    achievement: 'Achievement',
    'current-value': 'Current value',
    'goal-value': 'Goal value',
    'campaign-report.all-shops': 'All stores',
    'campaign-report.more-shops': '+ {shops} stores',
    name: 'Name',
    'marketing.campaigns.actions': 'Activity actions',
    'marketing.campaign.rating': 'Activity rating',
    'intranet.close_month-message':
      "You're going to close {shop}'s selling registers of {month}.",
    overview: 'Overview',
    channels: 'Channels',
    demographic: 'Demographic',
    'channels.app': 'App',
    'channels.play-center': 'Play center',
    'channels.web': 'Web',
    'channels.social-wifi': 'Social wifi',
    'channels.other': 'Other',
    'dashboards.age-range': 'Age range',
    'dashboards.email': 'Valid email',
    'dashboards.gender': 'Gender',
    'dashboards.email-notifications': 'Mail notifications',
    'dashboards.thirt-party-notifications': 'Third party notifications',
    'dashboards.visit-types': 'Visit types',
    'dashboards.phone': 'Phone',
    'dashboards.vat-number': 'Vat number',
    'dashboards.zip-code': 'Postal code',
    'dashboards.0-17': '< 18',
    'dashboards.18-24': '18-24',
    'dashboards.25-34': '25-34',
    'dashboards.35-44': '35-44',
    'dashboards.45-99': '> 45',
    'dashboards.registered-users': 'Total registered users',
    'dashboards.mail-notif-consent': 'Mail notifications',
    'dashboards.mail-partner-consent': 'Third party notifications',
    'dashboards.men': 'Man',
    'dashboards.women': 'Woman',
    'dashboards.other': 'Other',
    'dashboards.1': '1',
    'dashboards.2': '2',
    'dashboards.3': '3',
    'dashboards.4': '4',
    'dashboards.couple': 'Couple',
    'dashboards.family': 'Family',
    'dashboards.alone': 'Alone',
    yes: 'Yes',
    no: 'No',
    'dashboards.active-users': 'Active users',
    'dashboard.data-quality.history-of-registred-users':
      'History of registred users',
    'dashboard.data-quality.from-new-registers': 'registers without email',
    'dashboard.data-quality.new-registers-with-email':
      'Registrations with email',
    'dashboard.data-quality.from-new-registers-with-email':
      'registers with email and without notifications',
    'dashboard.data-quality.new-registers-with-email-and-notifications':
      'Registrations with email and notifications',
    'dashboard.data-quality.new-registers-by-channel':
      'Registrations by channel',
    'dashboard.data-quality.from-new-registers-without-phone':
      'registers without phone',
    'dashboard.data-quality.new-registers-with-phone':
      'Registrations with phone',
    'dashboard.data-quality.from-new-registers-with-phone':
      'registers with phone and without notifications',
    'dashboard.data-quality.new-registers-with-phone-and-notifications':
      'Registrations with phone and notifications',
    'dashboard.data-quality.from-new-registers-without-app-notifications':
      'registers without app notifications',
    'dashboard.data-quality.new-registers-with-app-and-notifications':
      'Registrations with app and notifications',
    'dashboard.data-quality.from-new-registers-without-name':
      'registers without name',
    'dashboard.data-quality.new-registers-with-name':
      'Registrations with name',
    'dashboard.data-quality.from-new-registers-without-age':
      'registers without age',
    'dashboard.data-quality.new-registers-with-age':
      'Registrations with age',
    'dashboard.data-quality.from-new-registers-without-postal-code':
      'registers without postal code',
    'dashboard.data-quality.new-registers-with-postal-code':
      'Registrations with zip code',
    'dashboard.data-quality.from-new-registers-without-id':
      'registers without ID',
    'dashboard.data-quality.new-registers-with-id': 'Registrations with ID',
    'dashboards.weekly.downloads': 'Downloads',
    'dashboards.none': 'None',
    'dashboards.dwell-time': 'Dwell time',
    'dashboards.per-active-users': 'Percentage of active users',
    'dashboards.zone1': 'Zone 1',
    'dashboards.zone2': 'Zone 2',
    'dashboards.zone3': 'Zone 3',
    'dashboards.zone4': 'Zone 4',
    'dashboards.with-family': 'With family',
    'dashboards.with-partner': 'With partner',
    'dashboards.male': 'Male',
    'dashboards.female': 'Female',
    'dashboards.recurrence': 'Visit Recurrence',
    'dashboards.registration-rate': 'Registration Rate',
    'dashboards.total-registered-users': 'Total Registered Users',
    'dashboards.total-app-users': 'Total Registered App Users',
    'dashboards.new-registered-users': 'Registered Users',
    'dashboards.new-app-users': 'Registered App Users',
    'dashboards.visits': 'Visits',
    attach_files: 'Attach files',
    'user.no-assigned-campaign-message': 'No assigned segmented activities',
    'user.no-assigned-segment-message': 'No assigned segments',
    'users.general_data': 'General data',
    'users.receipts': 'Receipts',
    'users.loyalty': 'Loyalty',
    'form-user.not-valid.email': 'Invalid email format',
    'ticket.raffle': 'Raffle',
    'ticket.loyalty-card': 'Loyalty Card',
    'ticket.rewards': 'Rewards',
    'ticket.coupon-exchanges': 'Exchanged coupons',
    'coupon-exchanges.coupon': 'Coupon',
    'coupon-exchanges.date-coupon-has-exchanged': 'Exchanged date',
    'coupon-exchanges.shop': 'Shop',
    'ticket.code': 'Code',
    'ticket.campaign-name': 'Activity',
    'ticket.created-at': 'Created at',
    'loyalty-card.campaign': 'Activity',
    'loyalty-card.stamps.value': 'Value',
    'loyalty-cards': 'Loyalty Cards',
    'loyalty-cards.unredeemed-stamps': 'Unredeemed stamps',
    'loyalty-cards.generate-error-message':
      "There was a problem when generating the loyalty cards and they haven't been created. Please, contact with the support team.",
    'loyalty-cards.generate-success-message':
      'The loyalty cards has been generated correctly.',
    'loyalty-cards.error-stamp-number':
    'Number of stamps must be greater than amount',
    'ticket.label': 'Rule',
    'ticket.campaign': 'Activity',
    'ticket.rule': 'Rule',
    'ticket.tickets-number': 'Tickets',
    'ticket.pending-balance': 'Pending balance',
    'reward.redeem-success': 'The reward has been redeemed succesfully',
    'reward.redeem-error':
      'There has been an error with redeeming the reward, please retry later',
    'reward.resend-success': 'The reward has been resend succesfully',
    'reward.resend-error':
      'There has been an error with the resent of the reward, please retry later',
    'reward.unredeem-success': 'The reward has been unredeemed succesfully',
    'reward.unredeem-error':
      'There has been an error with unredeeming the reward, please retry later',
    'reward.code': 'Code',
    'reward.campaign': 'Reward activity',
    'reward.valid-from': 'Valid from',
    'reward.valid-until': 'Valid until',
    'reward.exchanged-at': 'Redemption date',
    redeem: 'Redeem',
    resend: 'Resend',
    unredeem: 'Unredeem',
    'receipt.save-message': 'The receipt has been saved correctly',
    'users.code': 'Code',
    'users.commerce': 'Store',
    'users.amount': 'Amount',
    'users.date': 'Date',
    'users.campaigns': 'Activities',
    status: 'Status',
    'receipts.validation-date': 'Validation date',
    edit: 'Edit',
    delete: 'Delete',
    'receipt.campaign': 'Activity',
    'receipt.campaign.accumulative-amount': 'Accumulative amount',
    'receipt.stamps-number': 'Stamps number',
    'receipt.campaign.accumulated-balance': 'Accumulated balance',
    'receipt.pending-balance': 'Pending balance',
    'ticket.generate-error-message':
      "There was a problem when generating the tickets and they haven't beend created. Please, contact with the support team.",
    'ticket.generate-success-message':
      'The tickets has been generated correctly.',
    'import-csv.column-csv': 'CSV Field',
    'import-csv.column-database': 'Database field',
    'intranet.sales-year.validate.valid': 'There is no deviation',
    'intranet.sales-year.validate.acceptable': 'The deviation is under 5%',
    'intranet.sales-year.validate.invalid': 'The deviation is over 5%',
    'not-found': 'Not found',
    'breadcrumbs.main': 'Main',
    unknown: 'Unknown',
    select_one_option: 'Select an option',
    options_not_found: 'Options not found',
    'user-error': 'Something is wrong. Please check the following errors: ',
    'api-error':
      "Something is wrong and it's not your fault. Please, contact support with the following information:",
    'import-csv.formats-date.yyy-mm-dd': 'YYYY-MM-DD',
    'import-csv.formats-date.dd-mm-yyyy': 'DD/MM/YYYY',
    'import-csv.formats-date.mm-dd-yyyy': 'MM/DD/YYYY',
    'import-csv.update-options.update-insert':
      'Update existing data and insert new data',
    'import-csv.update-options.only-insert':
      'Only insert new data without updating existing data',
    'import-csv.separation-options.comma': 'Separator option comma: (,)',
    'import-csv.separation-options.semicon':
      'Separator option semicon: (;)',
    'info.default.succes.title': 'Success',
    'info.default.success.message':
      'The operation was executed succesfully.',
    'info.default.error.title': 'Error',
    'info.default.error.message':
      'An error occurred. Please try again later',
    'error.file.type.not_allowed': 'File type not allowed',
    'error.file.oversize': 'File exceeds the maximum file size',
    'error.file.not_allowed': 'File not allowed',
    'pager.out_of': 'out of',
    'communication.reject-close-month.subject':
      '{shopName} closing {month} rejected',
    'email-template.send.test': 'Send test e-mail',
    'actions.action_type': 'Action type',
    'actions.datetime': 'Date',
    'actions.title': 'Title',
    'actions.notes': 'Notes',
    'actions.staff': 'Staff',
    'crm.children.name': 'Name',
    'crm.children.bdate': 'Birthday date',
    'crm.children.gender': 'Gender',
    'check-in': 'Check in',
    'child.check-out': 'Check out',
    'crm.segments': 'Segments',
    send_email: 'Send e-mail',
    state: 'State',
    sales: 'sales',
    'cms.categories.general-data': 'General data',
    type: 'Type',
    category: 'Category',
    'start-date': 'Start date',
    'end-date': 'End date',
    active: 'Active',
    inactive: 'Inactive',
    scheduled: 'Scheduled',
    'cms.proposals.expire-success':
      'The proposal has been expired successfully',
    'cms.proposals.expire-error': 'Error on expiring proposal',
    'cms.proposals.publish-success':
      'The proposal has been published successfully',
    'cms.proposals.publish-error': 'Error on publishing proposal',
    'cms.proposals.request-success':
      'The proposal has been requested to publish successfully',
    'cms.proposals.request-error':
      'Error on requesting the publication of the proposal',
    'cms.proposals.reject-publication-success':
      'The proposal has been reject to publish successfully',
    'cms.proposals.reject-publication-error':
      'Error on rejecting the publication of the proposal',
    'cms.proposals.unpublish-success':
      'The proposal has been unpublished successfully',
    'cms.proposals.unpublish-error': 'Error on unpublishing proposal',
    preview: 'Preview',
    duplicate: 'Duplicate',
    unpublish: 'Unpublish',
    'reject-publishing': 'Reject the publication',
    publish: 'Publish',
    request: 'Request',
    expire: 'Expire',
    export: 'Export',
    store: 'Store',
    'coupon.exchange': 'Coupon exchange',
    'proposal.start-date': 'Start date',
    'proposal.end-date': 'End date',
    'proposals.active-app-users': 'Active app users',
    'proposals.users-segment': 'Active app users in segment',
    'proposals.views': 'Views',
    'proposals.shares': 'Shares',
    'proposals.likes': 'Likes',
    'proposals.dislikes': 'Dislikes',
    'cms.proposals.proposal-duplicated':
      'The proposal has been duplicated successfully',
    'cms.proposals.proposal-duplicated-error':
      'Error on duplicating proposal',
    'cms.proposal.general-data': 'General data',
    'cms.proposal.extra-attributes': 'Extra attributes',
    'push-notification.filters': 'Filters',
    'push-notification.status': 'Status',
    'push-notification.date': 'Date',
    'push-notification.name': 'Name',
    'push-notification.subject': 'Subject',
    'push-notification.segment': 'Segment',
    'push-notification.start-date': 'Start date',
    'push-notification.end-date': 'End date',
    'push-notification.frequency': 'Frequency',
    'push-notification.state': 'State',
    'push-notification.active-app-users': 'active users',
    'push-notification.sent': 'Sent',
    'push-notification.opened': 'Opened',
    detail: 'Detail',
    'cms.push-notification.copy-of': 'Copy Of',
    'cms.push-notification-duplicated':
      'The push notification has been duplicated successfully',
    'cms.push-notification-duplicated-error':
      'Error on duplicating push notification',
    'cms.push-notification.general-data': 'General data',
    Modules: 'Modules',
    'configuration.center.app-config.proposals-order.date': 'Date',
    'configuration.center.app-config.proposals-order.recommended':
      'Recommended',
    'configuration.center.extra-attributes': 'Extra attributes',
    'configuration.center.app-configuration': 'App configuration',
    'configuration.center.modules': 'Modules',
    'configuration.center.general-data': 'General data',
    'configuration.center.center-saved':
      'The center has been saved with success!',
    'dashboard.name': 'Name',
    'dashboard.category': 'Category',
    'dashboard.type': 'Type',
    'dashboard.internal': 'Internal',
    'dashboard.external-url': 'External URL',
    'dashboard.panel': 'Panel',
    code: 'Code',
    'linked-model': 'Linked Model',
    'configuration.extra-attributes.general-data': 'General data',
    email: 'Email',
    level: 'Level',
    'general-data': 'General data',
    'staff-members.already-exists':
      'It was impossible to create the user because it has been already created in another center. Please contact with the support team',
    'child.checkin-success-message': 'Child register correctly',
    'child.checkout-success-message': 'Child checkout confirmed',
    commerce: 'Shop',
    'crm.commerce.id': 'Id',
    'crm.commerce.zone': 'Zone',
    'crm.commerce.name': 'Name',
    'crm.commerce.company-reference-number': 'Shop code',
    'crm.commerce.phone': 'Phone',
    'crm.commerce.email': 'Email',
    'crm.commerce.minimum-age': 'Minimum age',
    'crm.commerce.maximum-age': 'Maximum age',
    'crm.commerce.genre': 'Gender',
    'crm.commerce.categories': 'Categories',
    'crm.commerce.pin': 'Pin',
    'crm.commerce.address': 'Address',
    'crm.commerce.latitude': 'Latitude',
    'crm.commerce.longitude': 'Longitude',
    'crm.commerce.image-url': 'Image URL',
    'crm.commerce.web-url': 'Web URL',
    'crm.commerce.whatsapp': 'Whatsapp',
    'crm.commerce.facebook': 'Facebook',
    'crm.commerce.instagram': 'Instagram',
    'crm.commerce.twitter': 'Twitter',
    'crm.commerce.linkedin': 'Linkedin',
    'crm.commerce.youtube': 'Youtube',
    'crm.commerce.tenant-code': 'Yardi code',
    Image: 'Image',
    zone: 'Zone',
    age: 'Age',
    gender: 'Gender',
    pin: 'Pin',
    'crm.commerce-access-information.users-related-error':
      'An error has occurred, you are trying to remove a store with customers associated.',
    'crm.commerce-access-information.sent-success':
      'The commerce access information has been sent successfully',
    'crm.commerce-access-information.sent-error': 'An error has occurred',
    'crm.commerce.general-data': 'General Data',
    'crm.commerce.extra-attributes': 'Extra attributes',
    'crm.commerce.physical-store': 'Physical Store',
    'cmr.commerce.hide-commerce.tooltip':
      "Current store is related with active proposals for this reason you can't hide it",
    'crm.commerces.qr-error':
      'There was an error trying to download the information requested, please try again later',
    'receipt.state.duplicated': 'Duplicated',
    'receipt.state.illegible': 'Illegible',
    'receipt.state.modified':
      'The receipt and the taken photo have not the same information',
    'receipt.state.other-center':
      'The receipt does not belong to this center',
    'receipt.state.out-of-period':
      'The receipt date is out of the activity period',
    'receipt.state.other-reason': 'Other reasons',
    'receipt.code': 'Code',
    'receipt.user': 'User',
    'receipt.email': 'Email',
    'receipt.store': 'Store',
    validate: 'Validate',
    show: 'Show',
    'receipt.validate.error-message-unable-to-redeem':
      'The tickets has been successfully validated but could not be redeemed.',
    required: '{attr} is required',
    'segment.users-in-segment': 'Total users',
    'segment.email-notifications': 'Email notifications',
    'segment.app-notifications': 'App notifications',
    'segment.categories': 'Filter categories',
    'segment.segment-category': 'Segment category',
    'segment.automatic-tags': 'Automatic tags',
    'crm.segment.tag-associated': 'Tag associated',
    'crm.segment.current-segment-is-not-editable':
      'Segment is not editable as it has been generated automatically when creating the tag',
    general_data: 'General data',
    'crm.users.code': 'Code',
    'crm.users.name': 'Name',
    'crm.users.last_name': 'Last name',
    'crm.users.email': 'Email',
    'crm.users.vat-number': 'VAT number',
    'crm.users.phone': 'Phone',
    'crm.tags.code': 'Code',
    'crm.tags.name': 'Name',
    'crm.tags.is-visible-on-app': 'Visible on app',
    'crm.tags.is-visible': 'Visible',
    'crm.tags.is-not-visible': 'Not visible',
    'crm.tags.is-interest': 'Interest',
    'user.match-options.id': 'Id',
    'user.match-options.email': 'Email',
    'user.match-options.dni': 'DNI',
    'user.id': 'Id',
    'user.address': 'Address',
    'user.register-date': 'Registration date',
    'user.app-notifications': 'App notifications',
    'user.bdate': 'Birthdate',
    'user.children-number': 'Children number',
    'user.city': 'City',
    'user.code': 'Customer code',
    'user.company': 'Company',
    'user.cp': 'Postal code',
    'user.dni': 'Vat number',
    'user.document-type': 'Document type',
    'user.email': 'Email',
    'user.has-email': 'Has email',
    'user.job-title': 'Job title',
    'user.last-name': 'Last name',
    'user.mail-notifications': 'Mail notifications',
    'user.mail-partners-notifications': 'Mail partners notifications',
    'user.marital-status': 'Marital status',
    'user.name': 'Name',
    'user.notes': 'Notes',
    'user.person-type': 'Person type',
    'user.purchaser-type': 'Purchaser type',
    'user.phone': 'Phone',
    'user.salesman': 'Salesman',
    'user.single-parent': 'Single parent',
    'user.single-parent-number': 'Single parent number',
    'user.large-family-number': 'Large family number',
    'user.large-family': 'Large family',
    'user.secondary-phone': 'Secondary phone',
    'user.sex': 'Gender',
    'user.signature-url': 'Signature URL',
    'user.valid-datetime': 'Valid datetime',
    'user.building': 'Building',
    'user.commerce': 'Commerce',
    'user.nationality': 'Nationality',
    'user.province': 'Province',
    'user.country': 'Country',
    'show-children': 'Show children',
    'user.electronic_communications_phrase':
      'I wish to receive electronic commercial communications',
    'user.partners_electronic_communications_phrase':
      'I wish to receive electronic commercial communications from thirds',
    'user.app-notifications-check': 'I wish to receive app notifications',
    'crm.users.user-saved': 'The user has been saved with success!',
    'crm.users.user-save-errors': 'The following errors have occurred:',
    shop: 'Store',
    'bonus-shop.created-at': 'Date',
    'communications.creator': 'Creator',
    'communications.subject': 'Subject',
    'communications.type': 'Type',
    'communications.datetime': 'Datetime',
    'communications.state': 'State',
    'file.name': 'Name',
    'file.type': 'Type',
    'file.size': 'Size',
    'file.updated_at': 'Date',
    'file.creator_id': 'Creator',
    'file.visibility': 'Visibility',
    download: 'Download',
    'folder.success-created': 'Success created',
    'flows.date': 'Date',
    footfall: 'Footfall',
    cars: 'Cars',
    cinemas: 'Cinemas',
    date: 'Date',
    'footfall.cars': 'Cars',
    'footfall.cinemas': 'Cinemas',
    'intranet.safte.anonymize-safte-data': 'Anonymize SAFT file',
    'intranet.safte.upload-safte-data': 'Upload anonymize file',
    'intranet.safte.uploaded-files-history': 'uploaded files status',
    'intranet.safte.sended-file':
      'File sent. The result of its processing will be notified by means of a communication on the intranet once it has been completed.',
    month: 'Month',
    amount: 'Amount',
    year: 'Year',
    'intranet.sales-year.button-show': 'Validate annual sales statement',
    'intranet.sales-years.dmi': 'DMI',
    'intranet.sales-years.declared': 'Sales declared',
    'intranet.sales-years.deviation-level': 'Deviation level',
    'intranet.sales-years.update-dmi-value': 'Update DMI value',
    'intranet-sales-year': 'Annual sales statement',
    'intranet.sales-years.reject-communication-subject':
      'Annual sales statement rejected, year ',
    'intranet.sales-years.reject-communication-message':
      'Your annual sales statement presents differences with regard to the sales declared monthly. Please check the amount declared and confirm them again for the following months: ',
    'intranet.sales-years.update-dmi-success-message':
      'The DMI value has been updated correctly.',
    'intranet.sales-years.update-dmi-error-message':
      'There was a problem with the updating and the DMI value has not be updated. Please, contact with the support team.',
    total: 'Total',
    'intranet.sales-years.yardi-upload-error-message':
      'There was a problem uploading yardi. Please, contact with the support team.',
    'sale.date': 'Date',
    'commerce.yardi-code': 'Yardi Code',
    'sale.concept': 'Concept sale',
    'sale.amount': 'Amount',
    'sale.tickets': 'Receipts',
    'informative-message.close-month.success.message':
      'The month was closed succesfully.',
    'informative-message.close-month.error.message':
      'An error occurred while closing the month. Please try again later',
    'sales.sale-concepts': 'Sale Concepts',
    'sales.upload-xlsx-error':
      'The file you have uploaded is not a valid format. Please, check the file and try again.',
    'informative-message.reject-close-month.success.message':
      'The close month was rejected succesfully. A communication was sent to the store',
    'informative-message.reject-close-month.error.message':
      'An error occurred while rejecting to close the month. Please try again later',
    'sales.upload-xlsx-success': 'XLSX has been uploaded with sucess!',
    'intranet.sales.estimated-sales-has-been-succesfully-saved':
      'Estimated sales has been successfully saved',
    'intranet.sales.estimated-sales-has-failed':
      'Somthing has failed, please try again later',
    'campaigns.id': 'Id',
    campaigntype: 'Activity type',
    campaign: 'Activity',
    'campaign.date-range': 'Date range',
    segment: 'Segment',
    'segmented-campaign-rule.aditional-stamps': 'Aditional stamps',
    'segmented-campaign-rule.multiple-allocation': 'Multiple allocation',
    'segmented-campaign-rule.unique-allocation': 'Unique allocation',
    'marketing.campaigns.loyalty': 'Loyalty',
    'campaign.new': 'New activity',
    'campaign.edit': 'Edit activity',
    'email-campaign.name': 'Campaign name',
    'email-campaign.subject': 'Subject',
    'email-campaign.segment.segmentName': 'Segment name',
    'email-campaign.sentDatetime': 'Sent date',
    'email-campaign.statistic.delivered': 'Delivered',
    'email-campaign.statistic.opened': 'Opened',
    'email-campaign.statistic.clicked': 'Clicked',
    'email-campaign.statistic.blocked': 'Blocked',
    'email-campaign.statistic.bounced': 'Bounced',
    'email-campaign.statistic.spam': 'Spam',
    stop: 'Stop',
    'marketing.emailing.duplicate-error': 'Error on duplicating campaign',
    'marketing.emailing.copy-of': 'Copy of',
    'marketing.emailing.stop-error': 'Error on stopping campaign',
    'email-campaign.form.general_info': 'General info',
    'email-campaign.form.desing_template': 'Design',
    'email-campaign.form.summary': 'Summary',
    'email-campaign.new': 'New e-mail campaign',
    'email-campaign.edit': 'Edit e-mail campaign',
    'estimated-cost': 'Estimated cost',
    'final-cost': 'Final cost',
    'reference-value': 'Reference value',
    deviation: 'Deviation',
    service: 'Service',
    supplier: 'Supplier',
    growth: 'Growth',
    label: 'Label',
    'ticket-amount': 'Amount',
    commerces: 'Stores',
    rating: 'Rating',
    close: 'Close',
    report: 'Report',
    description: 'Description',
    lineitem: 'Line item',
    'suppliers.local': 'Local',
    'suppliers.national': 'National',
    'suppliers.rating': 'Rating',
    role: 'Role',
    phone: 'Phone',
    'play-center.tutor': 'Tutor',
    'play-center.time': 'Time',
    'is-in-play-center': 'Is in play center',
    'pass-required':
      'Use 10 or more letters, with at least 1 number, 1 capital letter and 1 lower letter',
    'match-pass': 'The password fields do not match',
    'change-password.success-message':
      'The password has been successfully reset, please log in to the application.',
    'actions.call': 'Call',
    'actions.visit': 'Visit',
    'actions.email': 'Email',
    'actions.form': 'Form',
    'actions.sent_marketing_info': 'Sent marketing info',
    'actions.sent_marketing_info_title': 'Info sent',
    'actions.reservation': 'Reservation',
    'actions.reservation_title': 'Property reserved',
    'actions.rejection': 'Rejection',
    'actions.rejection_title': 'Offer rejected',
    'actions.signed': 'Signed',
    'actions.signed_title': 'Signed property',
    'leasing.accessories': 'Accesories',
    'leasing.books': 'Books',
    'leasing.bowling': 'Bowling',
    'leasing.childrens-apparel': 'Childrens apparel',
    'leasing.cinema': 'Cinema',
    'leasing.coffeeshop-icecream': 'Coffeeshop icecream',
    'leasing.electronics': 'Electronics',
    'leasing.fast-food': 'Fast food',
    'leasing.food': 'Food',
    'leasing.furniture': 'Furniture',
    'leasing.hairdressers-and-beauty-services':
      'Haridressers and beauty services',
    'leasing.handbags': 'Handbags',
    'leasing.home': 'Home',
    'leasing.home-apparel': 'Home apparel',
    'leasing.jewelry-watches': 'Jewelry watches',
    'leasing.lingerie': 'Lingerie',
    'leasing.mens-apparel': 'Mens apparel',
    'leasing.opticians-farmacy': 'Opticians farmacy',
    'leasing.other-leisure': 'Other leisure',
    'leasing.other-services': 'Other services',
    'leasing.outlet': 'Outlet',
    'leasing.perfumery-beauty-store': 'Perfumery beauty store',
    'leasing.pets': 'Pets',
    'leasing.phone-photo-games': 'Phone photo games',
    'leasing.restaurants': 'Restaurants',
    'leasing.shoes': 'Shoes',
    'leasing.special-food': 'Special food',
    'leasing.speciality-gift': 'Speciality gift',
    'leasing.sports': 'Sports',
    'leasing.supermarket': 'Supermarket',
    'leasing.toys': 'Toys',
    'leasing.travels': 'Travels',
    'leasing.womens-mens-apparel': 'Womens & mens apparel',
    'leasing.womens-apparel': 'Womens apparel',
    'leasing.young-apparel': 'Young apparel',
    'dinamic-action': 'Dynamic',
    'fixed-action': 'Static',
    'campaign.filters': 'Filters',
    value: 'Value',
    and: 'and',
    stars: 'stars',
    'marketing.kpi.coupon-percent': 'DMI - Coupon(%)',
    'marketing.kpi.tooltip.coupon-percent':
      'Redeemed coupons vs total coupons',
    'marketing.kpi.coupon-exchanges': 'DMI - Coupon use',
    'marketing.kpi.tooltip.coupon-exchanges': 'Amount of redeemed coupons',
    'marketing.kpi.impressions-number': 'DMI - Impressions',
    'marketing.kpi.tooltip.impressions-number':
      'Amount of impressions on the mobile app during the activity period',
    'marketing.kpi.active-users': 'DMI - Active users',
    'marketing.kpi.tooltip.active-users':
      'Amount of active users on the mobile app during the activity period',
    'marketing.kpi.visits': 'DMI - Visits',
    'marketing.kpi.tooltip.visits':
      'Amount of visits to the mall of customers with the mobile app',
    'marketing.kpi.attendant-number': 'Activity - Attendant',
    'marketing.kpi.tooltip.attendant':
      'Total number of people who have attended the event',
    'marketing.kpi.new-registered-users': 'Activity - New Users',
    'marketing.kpi.tooltip.new-registered-users':
      'New registered users during the activity',
    'marketing.kpi.previous-registered-users':
      'Activity - Previous Registered Users',
    'marketing.kpi.tooltip.previous-registered-users':
      'Amount of previously registered users that have taken part in the activity',
    'marketing.kpi.contestants': 'Activity - Contestants',
    'marketing.kpi.tooltip.contestants':
      'Total number of people who participated in the event',
    'marketing.kpi.new-registered-users-percent':
      'Activity - New Users (%)',
    'marketing.kpi.tooltip.new-registered-users-percent':
      'New registered users during the activity vs previously registered users',
    'marketing.kpi.cost-per-contestant': 'Activity - Cost / contestant',
    'marketing.kpi.tooltip.cost-per-contestant':
      'Average value of the activity cost per participant',
    'marketing.kpi.receipt-average-price': 'Activity - Average receipt',
    'marketing.kpi.tooltip.eceipt-average-price':
      'Average value of receipts during the activity per contestant',
    'marketing.kpi.total-receipts': 'Activity - Receipts',
    'marketing.kpi.tooltip.total-receipts':
      'Amount of registered receipts and total value',
    'marketing.kpi.total-receipts-value': 'Activity - Receipts value',
    'marketing.kpi.tooltip.total-receipts-value':
      'Total value of registered receipts',
    'marketing.kpi.eca-number': 'Activity - Publicity',
    'marketing.kpi.tooltip.eca': 'Unpaid advertising value of the activity',
    'campaign.status.active': 'Active',
    'campaign.status.closed': 'Closed',
    'campaign.status.finished': 'Finished',
    'campaign.status.planned': 'Planned',
    'campaign-types.comunication': 'Comunication',
    'campaign-types.digital': 'Digital',
    'campaign-types.event': 'Event',
    'campaign-types.loyalty': 'Loyalty',
    'campaign-types.others': 'Others',
    'campaign-types.promotion': 'Promotion',
    'campaign-types.rrpp': 'RRPP',
    'campaign-types.service': 'Service',
    'campaign-types.vip-service': 'VIP service',
    boy: 'Boy',
    girl: 'Girl',
    'alert-type': 'Alert type',
    'communication.type.issues': 'Issues',
    'communication.type.news': 'News',
    'communication.type.marketing': 'Marketing',
    'communication.type.access': 'Access',
    euros: 'Euros €',
    dollars: 'Dollars $',
    yen: 'Yen ¥',
    'dashboard.category.general': 'General',
    'dashboard.category.home': 'Home',
    'dashboard.category.traffic': 'Traffic',
    'dashboard.select-channel': 'Select channel',
    'dashboard-cms': 'Dashboard cms',
    'dmr-weekly': 'DMR weekly',
    'dashboard-crm': 'Dashboard crm',
    'dashboard.data-quality': 'Data quality',
    'dashboard.custom': 'Custom',
    week: 'Week',
    nif: 'NIF',
    cif: 'CIF',
    residence_card: 'Residence card',
    passport: 'Passport',
    'email-campaign.single-sending': 'Single sending',
    'email-campaign.every-15-minutes': 'Every 15 minutes',
    'email-campaign.every-30-minutes': 'Every 30 minutes',
    'email-campaign.hourly': 'Hourly',
    'email-campaign.daily': 'Daily',
    'email-campaign.weekly': 'Weekly',
    'emailing-campaign.filters': 'Filters',
    'email-campaign.active': 'Active',
    'email-campaign.archived': 'Archived',
    'email-campaign.scheduled': 'Scheduled',
    'email-campaign.draft': 'Draft',
    'configuration.extra-attributes.list.address': 'Address',
    'configuration.extra-attributes.list.text': 'Text',
    'configuration.extra-attributes.list.html': 'HTML',
    'configuration.extra-attributes.list.image': 'Image',
    'configuration.extra-attributes.list.url': 'URL',
    'configuration.extra-attributes.list.form': 'Advanced form',
    'configuration.extra-attributes.list.all': 'All',
    'configuration.extra-attributes.list.commerce': 'Commerce',
    'configuration.extra-attributes.list.proposal': 'Proposal',
    folder: 'Folder',
    doc: 'Doc',
    spreadsheet: 'Spreadsheet',
    pdf: 'PDF',
    presentation: 'Presentation',
    'zone-1': 'Zone 1',
    'zone-2': 'Zone 2',
    'zone-3': 'Zone 3',
    'zone-4': 'Zone 4',
    'file.roles.all': 'All',
    'file.roles.commerce': 'Shop',
    'file.roles.management': 'Management',
    'intranet.sales-years.status.unrequested': 'Unrequested',
    'intranet.sales-years.status.pending': ' Pending',
    'intranet.sales-years.status.rejected': 'Rejected',
    'intranet.sales-years.status.validated': 'Validated',
    none: 'None',
    raffle: 'Raffle',
    'loyalty-card': 'Loyalty Card',
    reward: 'Reward',
    'modules-playcenter': 'Playcenter',
    'modules-activities': 'Activities',
    'modules-sign': 'Sign',
    'modules-stays': 'Stays',
    'modules-sales': 'Sales',
    'modules-blog': 'Blog',
    'modules-cms': 'CMS',
    'modules-crm': 'CRM',
    'modules-footfall': 'Footfall',
    'modules-intranet': 'Intranet',
    'modules-marketing': 'Marketing',
    'modules-theaters': 'Theaters',
    'modules-multilevel': 'Multilevel',
    'modules-social-wifi': 'Social Wifi',
    'modules-tally': 'Advanced forms',
    'modules-connectif': 'Connectif',
    'modules-gymkhana': 'Gymkhana',
    'app-operator': 'App operator',
    'mof-type.url': 'URL',
    'mof-type.external-url': 'External URL',
    'mof-type.tally': 'Advanced form',
    'mof-type.proposals': 'Proposals',
    'mof-type.stores': 'Stores',
    'mof-type.gymkhana': 'Gymkhana',
    'mof-type.delivery': 'Delivery',
    'mof-type.emotions': 'Emotions',
    'mof-type.qr-capture': 'QR Capture',
    'mof-type.tao-wifi': 'TAO Wifi',
    'mof-type.video-capture': 'Video Capture',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    north: 'North',
    south: 'South',
    east: 'East',
    west: 'West',
    'physical-person': 'Physical person',
    'legal-person': 'Legal person',
    'proposal.filters': 'Filters',
    'proposal.type': 'Type',
    'proposal.is-coupon': 'Is coupon',
    'cms.proposal.draft': 'Draft',
    'cms.proposal.pending': 'Pending',
    'cms.proposal.scheduled': 'Scheduled',
    'cms.proposal.active': 'Active',
    'cms.proposal.expired': 'Expired',
    center: 'Center',
    'first-property': 'First property',
    'second-property': 'Second property',
    investor: 'Investor',
    'push-notification.action-type.default': 'Default',
    'push-notification.action-type.proposal': 'Proposal',
    'push-notification.single-sending': 'Single sending',
    'push-notification.hourly': 'Hourly',
    'push-notification.daily': 'Daily',
    'push-notification.weekly': 'Weekly',
    'push-notification.on-start-visit': 'On start visit',
    'push-notification.on-end-visit': 'On end visit',
    'push-notification.active': 'Active',
    'push-notification.archived': 'Archived',
    'push-notification.scheduled': 'Scheduled',
    'push-notification.draft': 'Draft',
    'receipt.status.pending': 'Pending',
    'receipt.status.pending.tooltip':
      'The receipt has been created by an user and it has to be validated by a CRM Operator',
    'receipt.status.redeemed': 'Redeemed',
    'receipt.status.redeemed.tooltip':
      'The receipt has already been redeemed',
    'receipt.status.rejected': 'Rejected',
    'receipt.status.rejected.tooltip':
      'The receipt information does not match with the taken image and it has to be adjusted',
    'crm.filters-section.source': 'Source',
    campaign_id: 'Activity',
    'receipt.status.draft': 'Draft',
    'receipt.status.draft.tooltip':
      'The receipt has not all data and it will be fill in by an external process',
    'receipt.status.not-redeemable': 'Not redeemable',
    'receipt.status.not-redeemable.tooltip':
      'The receipt is not associated to any loyalty activity in the time required to turn it into stamps or tickets',
    'receipt.status.redeemable': 'Redeemable',
    'receipt.status.redeemable.tooltip':
      'The receipt is available to be turn into stamps or tickets for the associated loyalty activity',
    'receipt.status.not-yet-redeemable': 'Not yet redeemable',
    'receipt.status.not-yet-redeemable.tooltip':
      'The receipt can not be turn into stamps or tickets because the needed days to activate it have not elapsed',
    lead: 'Lead',
    removed: 'Removed',
    child: 'Child',
    nephew: 'Nephew',
    grandchild: 'Grandchild',
    others: 'Others',
    'on-signing-date': 'On signing date',
    'from-unit-delivery-date': 'From unit delivery date',
    'from-unit-opening-date': 'From unit opening date',
    all: 'all',
    'any-of': 'any of',
    man: 'Man',
    woman: 'Woman',
    other: 'Other',
    'shopMonth.state.without_sales': 'Without sales',
    'shopMonth.state.unclosed': 'Open',
    'shopMonth.state.closed': 'Closed',
    'shopMonth.state.validated': 'Validated',
    'intranet.sovos.pending': 'pending',
    'intranet.sovos.imported': 'imported',
    'intranet.sovos.invalid': 'invalid',
    admin: 'Admin',
    calibrator: 'Calibrator',
    'crm-manager': 'CRM Manager',
    'crm-operator': 'CRM Operator',
    'external-api': 'External API',
    manager: 'Manager',
    owner: 'Owner',
    scmanager: 'SC Manager',
    'store-manager': 'Store Manager',
    'web-operator': 'Agency',
    'staff-level-sales': 'Sales',
    'staff-level-services': 'Services',
    'stamp.status.redeemed': 'Redeemed',
    'stamp.status.available': 'Available',
    'stamp.status.unavailable': 'Unavailable',
    'supplier-contact.filters': 'Filters',
    'suppliers.filters': 'Filters',
    'mofs.url-parameters.name': 'Name',
    'mofs.url-parameters.email': 'Email',
    id: 'id',
    'center-id': 'Center id',
    'register-date': 'Register Date',
    bdate: 'Birthday',
    sex: 'Gender',
    cp: 'Postal code',
    'visit-type': 'Visit type',
    eula: 'EULA',
    'last-activity': 'Last Activity',
    'last-name': 'Last name',
    address: 'Address',
    dni: 'DNI',
    'app-notifications': 'App notifications',
    'mail-notifications': 'Electronic commercial communications',
    province: 'Province',
    city: 'City',
    children: 'Children',
    usertypes: 'Usertypes',
    campaigns: 'Activities',
    receipts: 'Receipts',
    opened: 'Opened',
    not_opened: 'Not opened',
    clicked: 'Clicked',
    not_clicked: 'Not clicked',
    'user.single_parent': 'Single parent',
    'crm.users.is-senior': 'Senior',
    province_id: 'Province',
    'has-valid-email': 'Has valid email',
    mail_notifications: 'Electronic commercial communications',
    mail_partners_notifications:
      'Electronic commercial communications from thirds',
    'influence-zone': 'Zones of influence',
    'user.child-bday': 'Children birthday (days)',
    following: 'the following',
    days: 'Days',
    'user.child-in-play-center-days': 'children in play center (days)',
    in_the_last: 'in the last',
    'crm.users.children-bdate-month-day': 'Children birthday (range)',
    'crm.users.bdate-month-day': 'Birthdate (range)',
    'crm.users.bdate-today': 'Birthdate (today)',
    'user.bday-days': 'Birthday (days)',
    children_number: 'Children number',
    children_age: 'Children age',
    years: 'years',
    nationality_id: 'Nacionality',
    company: 'Company',
    channel_id: 'Channel',
    'user.redeemed-loyalty-cards-range': 'Redeemed loyalty cards (range)',
    'user.redeemed-loyalty-cards': 'Redeemed loyalty cards',
    'user.in-campaign': 'In activity',
    'user.redeemable-stamps-range': 'Redeemable stamps (range)',
    'user.redeemable-stamps': 'Redeemable stamps',
    'user.redeemed-rewards-range': 'Redeemed rewards (range)',
    'user.redeemed-rewards': 'Redeemed rewards',
    'user.redeemed-reward-days': 'redeemable rewards (range)',
    'user.days-until-campaign-expiration':
      'days until expiration of the activity',
    tags: 'Tags',
    'user.reference-building': 'Reference building',
    spending_receipts: 'Spending receipts',
    '€': '€',
    between: 'between',
    spending_receipts_number: 'Spending receipts (by number)',
    'crm.filters-section.receipts': 'receipts',
    'user.seniority': 'Seniority',
    'user.spending-receipt-days': 'Spending receipts (last days)',
    'days-ago': 'days ago',
    'user.number-receipt-days': 'Receipts number (last days)',
    'user.most-purchased-subfamily': 'Most purchased subfamily (days)',
    'user.most-purchased-brand-days': 'Most purchased brand (days)',
    'items-form': 'items from',
    'user.most-purchased-brand-dates': 'Most purchased brand (range)',
    'user.segment': 'Segment',
    'user.users-state-on-club': 'User’s state on club',
    visit_building: 'Visit building',
    'coupon-used': 'Coupon used',
    since_last_visit: 'Since last visit(days)',
    inactivity_days: 'App inactivity days',
    'crm.filters-section.email_engagement_last_days': ' Emails (days)',
    'days-before': 'days before',
    'crm.filters-section.email_engagement_campaign': 'Emails (campaign)',
    in: 'in',
    'crm.filters-section.email_engagement_dates': 'Emails (dates range)',
    start: 'start',
    end: 'end',
    without_channel: 'Without Channel',
    without_phone: 'Without phone',
    without_cp: 'Without CP',
    without_bdate: 'Without birthday',
    without_dni: 'Without ID',
    without_name: 'Without name',
    without_sex: 'Without gender',
    'crm.filters-section.proposals-engagement-last-days':
      'Proposal (last days)',
    'crm.filters-section.proposals-engagement-campaign': 'Proposal',
    proposal: 'Proposal',
    'crm.filters-section.proposals-engagement-dates':
      'Proposal (date range)',
    'crm.filters-section.proposals-engagement-category':
      'Proposal (category last days)',
    'with-category': 'with category',
    'crm.filters-section.created-receipts-commerce': 'Receipts by commerce',
    'crm.filters-section.associated_to': 'associated to',
    'crm.filters-section.sales-engagement-coupons': 'Coupon number',
    'coupon-count': 'number',
    'crm.filters-section.campaign-participations':
      'Activity participations',
    'crm.filters-section.dates': 'Dates',
    'crm.filters-section.general-data': 'General data',
    'crm.filters-section.children': 'Children',
    'crm.filters-section.demographic': 'Demographic',
    'crm.filters-section.communications': 'Communications',
    'crm.filters-section.behavior-and-type': 'Behavior and types',
    'crm.filters-section.loyalty': 'Loyalty',
    'crm.filters-section.visit': 'Visit',
    'crm.filters-section.sales': 'Sales',
    'crm.filters-section.email_engagement': 'Email engagement',
    'crm.filters-section.proposals-engagement': 'Proposal engagement',
    'crm.filters-section.data-quality': 'Data quality',
    viewed: 'Viewed',
    liked: 'Liked',
    shared: 'Shared',
    'crm.tags.filters': 'Filters',
    draft: 'Draft',
    published: 'Published',
    bookable: 'Bookable',
    'draft-bookable': 'Draft-bookable',
    single: 'Single',
    couple: 'With partner',
    family: 'With family',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    'sunday-short': 'Sun',
    'monday-short': 'Mon',
    'tuesday-short': 'Tues',
    'wednesday-short': 'Wed',
    'thursday-short': 'Thurs',
    'friday-short': 'Fri',
    'saturday-short': 'Sat',
    'january-short': 'Jan',
    'february-short': 'Feb',
    'march-short': 'Mar',
    'april-short': 'Apr',
    'may-short': 'May',
    'june-short': 'Jun',
    'july-short': 'Jul',
    'august-short': 'Aug',
    'september-short': 'Sep',
    'october-short': 'Oct',
    'november-short': 'Nov',
    'december-short': 'Dec',
    'error.mof.image.required': 'Image is required',
    'error.mof.type.required': 'Type is required',
    'error.mof.name.required': 'Name is required',
    'error.mof.start-date.required': 'Start date is required',
    'error.mof.end-date.required': 'End date is required',
    'error.mof.url.required': 'URL is required',
    'error.mof.category.required': 'Category is required',
    'campaign.end-date-after-start-date':
      'End date is set before start date',
    'error.crm.children.entryTime.required': 'Entry time is required',
    'error.crm.children.expectedExitTime.required':
      'Expected exit time is required',
    'error.child.name.required': 'Name is required',
    'error.child.relationship.required': 'Relationship is required',
    'error.child.sex.required': 'Gender is required',
    'error.child.bdate.required': 'Birthday is required',
    'date.from-to': 'From {from} to {to}',
    'error.commerce.categories.required': 'A category is required',
    'error.commerce.gender.required': 'A gender is required',
    'error.commerce.imageUrl.required': 'Image url is required',
    'error.commerce.name.required': 'Name is required',
    'error.commerce.whatsapp-message.required':
      'A WhatsApp Message is required',
    'error.commerce.whatsapp-phone.format':
      "WhatsApp phone doesn't have a code area. It should follow the format +346xxxxxxxx.",
    'error.commerce.zone.required': 'Zone is required',
    'error.coupon.usageLimit.required': 'Coupon limit is required',
    'error.coupon.text.required': 'Text is required',
    'error.coupon.commerces.required': 'Store required',
    'error.dashboard.name.required': 'Name is required',
    'error.dashboard.category.required': 'Category is required',
    'error.dashboard.panel.required': 'Dashboard panel is required',
    'error.dashboard.url.required': 'URL is required',
    'error.email-campaign-template.name.required': "Name can't be blank",
    'error.email-campaign-template.htmlBody.required':
      "Campaign template can't be blank",
    'error.email-campaign.name.required': "Name can't be blank",
    'error.email-campaign.subject.required':
      "E-mail subject can't be blank",
    'error.email-campaign.htmlBody.required':
      "Campaign template can't be blank",
    'error.email-campaign.segment.required': "Segment field can't be blank",
    'error.email-campaign.date.invalid_past':
      "Start date can't be in the past",
    'error.email-campaign.date.required': "Date can't be blank",
    'error.email-campaign.endDatetime.post_startDatetime':
      'End date must be after start date',
    'error.extra-attributes.label.required': 'Name is required',
    'error.extra-attributes.code.required': 'Code is required',
    'error.extra-attributes.extra-type.required': 'Type is required',
    'error.extra-attributes.linked-model.required':
      'Linked model is required',
    'error.extra-attributes.channel.required':
      'At least one channel must be selected',
    'error.communication.companion.required':
      'Some companions have blank data',
    'error.communication.commerce.required': 'Select at least one shop',
    'error.file.name.required': "Name can't be blank",
    'error.file.url.required': 'File is required',
    'error.file.roles.required': 'Select a role',
    'error.file.commerces.required': 'Select at least one shop',
    'error.sale.date.invalid_future': "You can't add sales of future dates",
    'error.sale.date.closed-month-error':
      "You can't add sales in closed months",
    'error.load.repeated-fields':
      'There are database fields selected more than once.',
    'error.load.format-date.required': 'Format date is required',
    'error.proposal.category.required': 'Category is required',
    'error.proposal.description.required': 'Description is required',
    'error.proposal.channel.required':
      'At least one channel must be selected',
    'error.proposal.end-date.required': 'End date required',
    'error.proposal.end-date.after':
      'End date can not be before start date',
    'error.proposal.eventStartDate.required': 'Event startDate is required',
    'error.proposal.event-end-date.required': 'Event end date required',
    'error.proposal.event-end-date.after':
      'Event end date can not be before event start date',
    'error.proposal.gender.required': 'Gender is required',
    'error.proposal.maximumAge.required': 'Maximum age is required',
    'error.proposal.minimumAge.required': 'Minimum age is required',
    'error.proposal.name.required': 'Name required',
    'error.proposal.proposalType.required': 'Type is required',
    'error.proposal.startDate.required': 'StartDate is required',
    'push-notification.end-datetime': 'Ending date is required',
    'error.push-notification.event-end-date.after-startdate':
      'Event end date can not be set before event start date',
    'push-notification.start-datetime': 'Start date is required',
    'error.tickets.date.required': 'Date is required',
    'error.tickets.amount.required': 'Amount is required',
    'error.tickets.commerce.required': 'Store is required',
    'error.reward.campaign.required': 'Activity is required',
    'error.reward.validUntil.required': 'The expiration date is required',
    'concepts.airplane_tickets': 'Airplane tickets',
    'concepts.bar': 'Bar',
    'concepts.cancellations': 'Cancellations',
    'concepts.cinema_tickets': 'Cinema tickets',
    'concepts.coffee': 'Coffee',
    'concepts.drones': 'Drones',
    'concepts.equipment': 'Machinery (equipment)',
    'concepts.free_devices': 'Free Devices',
    'concepts.game': 'Game',
    'concepts.general': 'General',
    'concepts.general_variable': 'General Merchandise Variable Rent',
    'concepts.gm1': 'General Merchandise 1',
    'concepts.gm2': 'General Merchandise 2',
    'concepts.magazines': 'Newspaper and magazines',
    'concepts.phonecard': 'Phonecard',
    'concepts.prescription': 'Prescription',
    'concepts.pub': 'Pub',
    'concepts.radio-modelism': 'Radio modelism',
    'concepts.receipts': 'Uploads / Invoices',
    'concepts.school_mat': 'School Material',
    'concepts.stamps_and_values': 'stamps and values',
    'concepts.telecommunications': 'Services Telecommunications',
    'concepts.tobacco': 'Tobacco',
    'concepts.tobacco_game': 'Tobacco and Game',
    'concepts.trans_currency': 'Trans. Currency',
    'concepts.uploads_of_credit': 'Uploads of credit',
    'concepts.online_sales': 'Online sales',
    'concepts.rent_car_services': 'Rent car services',
    'concepts.fees_delivery': 'Fees delivery',
    'name-required': 'Name is required',
    'yardi-code-required': 'Yardi code is required',
    'centers-required': 'Centers is required',
    'channel-required': 'The channel is required',
    'country-required': 'Country is required',
    'default-building-required': 'A default building is required',
    'email-required': 'Email is required',
    'last-name-required': 'Last name is required',
    'level-required': 'Level is required',
    'pass-format':
      'Use 10 or more letters, with at least 1 number, 1 capital letter and 1 lower letter',
    'current-pass-required': 'Current pass is required',
    'error.user.gdpr': 'The GPDR should be accepted',
    'phone-required': 'Phone is required',
    'error.user.registration-date.required':
      'Registration date is required',
    'error.user.email.required': 'Email is required',
    'error.user.name.required': 'Name is required',
    'error.user.cp.required': 'Postal code is required',
    'error.channels.required': 'Channels is required',
    'title.header.dashboards.show': 'Dashboard',
    'title.header.cms.categories': 'Categories',
    'cms.new-category': 'New category',
    'title.header.cms.mofs': 'MOF',
    'title.header.proposals': 'Proposals',
    'cms.new-proposal': 'New proposal',
    'title.header.push-notifications': 'Push notifications',
    'push-notification.new': 'New push notification',
    'title.header.configuration': 'Configuration',
    'title.header.configuration.centers': 'Centers',
    'configuration.new-center': 'New center',
    'title.header.configuration.channels': 'Channels',
    'configuration.new-channel': 'New Channel',
    'title.header.configuration.dashboard': 'Dashboard configuration',
    'dashboards.new': 'New dashboard',
    'title.header.configuration.email-templates': 'E-mail templates',
    'title.header.configuration.extra-attributes': 'Extra Attributes',
    'informative-message.change-password.success.message':
      'Your password has been changed. You will be redirected to the login panel',
    'title.header.configuration.staff-members': 'Staff members',
    'staff-members.new': 'New Staff Member',
    'crm.children.add': 'Add children',
    'title.header.crm.commerces': 'Stores',
    'commerces.new': 'New store',
    'title.header.crm.receipts': 'Receipts',
    'crm.receipts': 'Receipts',
    'title.header.crm.segment-categories': 'Segment categories',
    'segment-categories.new': 'New segment categories',
    'title.header.crm.segments': 'Segments',
    'crm.new-segment': 'New segment',
    'crm.tags': 'Tags',
    'crm.tag.new': 'New tag',
    'title.header.crm.users': 'Customers',
    'crm.new-user': 'New customer',
    'cms.users.segments-updated-message':
      'The segment is updated successfully',
    'title.header.desktop': 'Desktop',
    'title.header.bonus-requests': 'Bonus requests',
    'intranet.new-bonus-request': 'New bonus request',
    'title.header.communication': 'Communications',
    'intranet.new-communication': 'New communication',
    'title.header.files': 'Files management',
    'file.new': 'New file',
    'title.header.footfall': 'Footfall',
    'title.header.safte': 'SAFT-E',
    'title.header.intranet.sales-years': 'Annual sales statement',
    'title.header.sales': 'Sales management',
    'title.header.subtypes': 'Communication types',
    'intranet.new-subtype': 'New subtype',
    'title.header.marketing.campaigns': 'Activities',
    'marketing.new-campaign': 'New activity',
    'title.header.emailing.campaigns': 'Email campaigns',
    'marketing.new-emailing': 'New emailing campaign',
    'email-campaign.htmlBody.init_greeting': 'Edit your e-mail here :)',
    'title.header.marketing.lineitems': 'Line items',
    'title.header.marketing.services': 'Services',
    'title.header.marketing.suppliers': 'Suppliers',
    'play-center-stays': 'Play center',
    'title.header.testing': 'Test header',
    'informative-message.upload-yardi.success':
      'The Yardi file has been uploaded correctly.',
    'informative-message.upload-yardi.error':
      "The Yardi file hasn't been uploaded correctly. Please, try again or contact with an admin.",
    'analytics.no-dashboard': 'There are no dashboards available',
    'category.new': 'Add new category',
    'category.edit': 'Edit category',
    'cms.category': 'Category',
    'cms.category.general-data': 'General data',
    'categories.parent-categories': 'Parent category',
    'categories.categories-search': 'Search category',
    'category.web-image': 'Attach image (png, jpg, gif or jpg)',
    'mofs.modal-title-new': 'New MOF button',
    'mofs.modal-title-edit': 'Edit a MOF button',
    'mofs.button.show-only-to': 'Show only to',
    'mofs.button.position': 'Position',
    'mofs.button.type': 'MOF button type',
    'mofs.button.url': 'URL',
    'mofs.url-parameters': 'URL parameters',
    'mofs.url-image.helper.png-transparent':
      'The image format must be png, the background should be transparent and the image should be squared with a 128x128 resolution so it will be shown correctly in the app',
    'mofs.url-image': 'Button image',
    'mofs.save': 'Save',
    'mofs.button.want-remove': 'Do you really want to remove this button?',
    cancel: 'Cancel',
    want_duplicate:
      'Do you really want to duplicate the {modelName} {name}?',
    want_publish: 'Do you really want to publish this {modelName} {name}?',
    want_request:
      'Do you really want to request the publication of this {modelName} {name}?',
    want_unpublish:
      'Do you really want to unpublish this {modelName} {name}?',
    rejectPublish: 'Reject publish',
    want_expire: 'Do you really want to expire the {modelName} {name}?',
    'proposal.export-feedback': 'Select feedback type',
    select: 'Select',
    'proposal.segment-all': 'All users',
    'proposal.new': 'New proposal',
    'proposal.edit': 'Edit proposal',
    'proposal.general-data': 'General data',
    'proposal.publish-on-app': 'Publish on App',
    'proposal.publish-on-web': 'Publish on Web',
    'proposal.app-image': 'App Image',
    'proposal.web-image': 'Web Image',
    'proposal.settings': 'Settings',
    'proposal.store-search': 'Search store',
    'proposal.start-datetime': 'Start datetime',
    'proposal.end-datetime': 'End datetime',
    'proposal.event': 'Event',
    'proposal.event-start-event-datetime': 'Event start datetime',
    'proposal.event-end-event-datetime': 'Event end datetime',
    'proposal.coupon': 'Coupon',
    'proposal.coupon.limit': 'Coupon limit',
    'proposal.hide-home':
      "Show only in the selected category (this proposal won't be shown in News/Home)",
    'proposal.featured': 'Featured',
    'proposal.segments': 'Segments',
    'proposal.segment.list': 'Segments list',
    'segment.total_users_reached': 'Total users reached',
    'proposal.custom-target': 'Custom target',
    minimumAge: 'Minimum age',
    maximumAge: 'Maximum age',
    'proposal.gender': 'Gender',
    'proposal.extra.text': 'Text',
    'commerce.extra.address': 'Address extra attributes',
    'commerce.extra.url': 'URL extra attributes',
    'commerce.html': 'HTML extra attributes',
    'commerce.extra.image': 'Image extra attributes',
    'commerce.extra.form': 'Advanced form',
    'push-notification': 'Push notification',
    'push-notification.edit': 'Edit push notification',
    'push-notification.detail': 'Detail of a push notification',
    'push-notification.general-data': 'General data',
    'push-notification.message': 'Message',
    'push-notification.image': 'Image',
    'push-notification.image.info':
      'This image would be only shown in the notification push when the action type selected is "default".',
    'push-notification.segmentation': 'Segmentation',
    'push-notification.campaign': 'Activity',
    'push-notification.sending-date': 'Sending date',
    'email-campaign.start-date': 'Start date',
    'email-campaign.end-date': 'End date',
    'push-notification.advanced-options': 'Advanced options',
    'push-notification.action-type': 'Action type',
    'push-notification.proposals': 'Proposals',
    'push-notification.proposals-search': 'Search proposal',
    'push-notification.proposal-image.info':
      "The images of the push notifications can't be larger than 1 MB. Please, check the image's size before publishing the push notification.",
    'push-notification.proposal-image.warning':
      'The image of the proposal is larger than 1 MB. Please, reduce the size of the image before publishing the push notification.',
    'cms.push-notification.want-publish':
      'Do you want to publish the push notification?',
    save: 'Save {modelName}',
    'folder.new.current_dir': 'Create a new folder',
    folder_name: 'Folder name',
    name_required: "Name can't be blank",
    'folder.add': 'Create folder',
    'weekday.monday': 'Monday',
    'weekday.tuesday': 'Tuesday',
    'weekday.wednesday': 'Wednesday',
    'weekday.thursday': 'Thursday',
    'weekday.friday': 'Friday',
    'weekday.saturday': 'Saturday',
    'weekday.sunday': 'Sunday',
    'campaign-report.select-visible-kpis': 'Select visible KPIs',
    'campaign-report.rating': 'Activity rating',
    'campaign-report.description': 'Description',
    'campaign-report.campaign-type': 'Activity type',
    'campaign-report.notes': 'Notes',
    'campaign-report.shops': 'Stores',
    'campaign-report.campaign-goals': 'Activity goals',
    'campaign-report.campaign-actions': 'Activity actions',
    'campaign-report.reference-period': 'Reference period',
    'campaign-report.campaign-kpi': 'Activity kpi',
    'campaign-report.campaign-cost': 'Activity cost',
    'campaign-report.suppliers-rating': 'Suppliers rating',
    supplierRating: 'Supplier rating',
    'currently-in-playcenter': 'Currently in play center',
    'children-count': '{count} child',
    'children-count_plural': '{count} children',
    close_month: 'Close month',
    closed_month_pending: 'Pending',
    pending: 'Pending',
    are_you_sure: 'Are you sure?',
    accept: 'Accept',
    'communication.type.tabs.inbox': 'Inbox',
    'communication.type.tabs.sent': 'Sent',
    'active.users': 'Active users',
    'kpi.tooltip.active-users':
      'Number of users that have the app active in the selected period',
    downloads: 'Downloads',
    'kpi.tooltip.downloads': 'App downloads in the selected period',
    'installed.apps': 'Registered apps',
    'kpi.tooltip.installed-apps':
      'Number of registered apps in the selected period',
    visits: 'Visits',
    'kpi.tooltip.visits':
      'Number of visits to the mall in the selected period',
    'dashboards.visits-users-chart': 'Visits vs Active users',
    'visits.recurrence': 'Recurrence',
    'kpi.tooltip.recurrence':
      'Average number of times that users visit the mall in the selected period',
    'average.visit.time': 'Dwell time',
    'kpi.tooltip.average-visit-time':
      'Average stay time in the mall in the selected period',
    'dashboards.channels.mail-notifications':
      'Registered users with active mail notifications',
    'dashboards.app-users': 'App users',
    'kpi.tooltip.app-users':
      'Number of registered users via app channel in the selected period',
    'kpi.vs-last-month': 'VS Last month',
    'kpi.vs-same-month-last-year': 'VS Same month last year',
    'dashboards.play-center-users': 'Play center users',
    'kpi.tooltip.play-center-users':
      'Number of registered users via play center channel in the selected period',
    'dashboards.web-users': 'Web users',
    'kpi.tooltip.web-users':
      'Number of registered users via web channel in the selected period',
    'dashboards.rsocial-users': 'Social wifi users',
    'kpi.tooltip.social-users':
      'Number of registered users via social wifi channel in the selected period',
    'dashboards.other-users': 'Other users',
    'kpi.tooltip.rother-users':
      'Number of registered users via other channels in the selected period',
    'dashboards.channels.verified-mail':
      'Registered users with verified mail',
    'dashboards.registers-per-day': 'Monthly registers per day',
    'dashboards.quality-data-chart': 'Quality of user data',
    'dashboards.influence-zone': 'Influence zone',
    'dashboards.registration-vs-consent-chart': 'Registration vs Consent',
    'kpi.tooltip.registered-users-total':
      'Number of registered users in the selected period',
    'dashboards.active-vs-registered-users-chart':
      'Active Users vs Registered Users',
    'data-quality.select-dates': 'Select dates',
    'dmr-weekly.select-week': 'Select week',
    'dashboards.weekly.year-average': 'Average of the year',
    'dashboards.weekly.downloads-chart': 'Downloads (Week)',
    'dashboards.weekly.database-increase':
      'New registered users per channel',
    'dashboards.weekly.total-registers': 'Total registered users',
    'dashboards.weekly.registered-users-channel':
      'Registered users per channel',
    'dashboards.weekly.last-eight-weeks-chart':
      'Registered users since 8 weeks ago per channel',
    'kpi.tooltip.dwell-time':
      'Average stay time in the mall in the selected period',
    'dashboards.weekly.dwell-time-chart': 'Dwell time (Week)',
    'dashboards.weekly.user-profile-selected-week':
      "User's profile of the selected week",
    'dashboards.influence-zones': 'Influence zones',
    'dashboards.weekly.user-profile-year-average':
      "Year average of user's profile",
    'dashboards.registered-app-users': 'Registered app users',
    'kpi.tooltip.registered-app-users':
      'New users registered by the app channel in the selected period',
    average: 'Average',
    'dashboards.registered-users-total': 'Total Registered Users',
    'dashboards.registered-users-chart':
      'App Registries vs Total Registries (Month)',
    'dashboards.active-users-chart':
      'Active Users vs % Active Users (Month)',
    'dashboards.registered-total-users-chart':
      'App Registries vs Total Registries (Aggregate)',
    'dashboards.registration-rate-chart': 'Registration Rate (Month)',
    'dashboards.visits-competition-chart': 'Visits vs Competition (Month)',
    'dashboards.frequency-chart':
      'Visits Recurrence vs Competition (Month)',
    'dashboards.dwell-chart': 'Dwell Time vs Competition (Month)',
    'dashboards.user-profiles-gender-chart': 'Gender',
    'dashboards.user-profiles-family-chart': 'Family',
    'dashboards.user-profiles-catchment-area-chart': 'Catchment Area',
    'dashboards.user-profiles-age-chart': 'Age',
    want_remove: 'Do you really want to delete this {modelName}?',
    error_browser_iframe: 'Your browser does not support iframes',
    'email-template.select_one': 'Select one template',
    'email-campaign.not_found': 'E-mail campaigns not found',
    'email-campaign': 'E-mail campaign',
    'intranet.communications.title-eula':
      'Intranet Communications - RGPD acceptance',
    'intranet.communications.contact-it':
      'There was an error while accepting third party communications. Please, contact with IT and send to them the following trace error:',
    'intranet.communications.error': 'Error updating staff member',
    'intranet.communications.eula-communications-description':
      "Before using the intranet communication's system, you need to accept the terms and conditions.",
    'intranet.communications.accept-eula':
      'Yes, I wish to receive electronic communications from third party.',
    add: 'Add',
    'want-add-files': 'Do you want to add files?',
    'add-files': 'Add one or more files',
    image: 'Image',
    document: 'Document',
    excel: 'Excel',
    powerpoint: 'Powerpoint',
    file_max_allowed: 'The maximum allowed size is {size}',
    'actions.select_action_type': 'Select action type',
    'actions.date': 'Date',
    'action.check_as_done': 'Mark action as completed',
    response: 'Response',
    send: 'Send',
    'communication.type': 'Type',
    'communication.subtype': 'Subtype',
    'communication.label.to_roles': 'To',
    'communication.subject': 'Subject',
    'communication.message': 'Message',
    'communication.to_commerces': 'To shops',
    access_data: 'Access data',
    access_date: 'Access date',
    access_head: 'Head of access',
    companions: 'Companions',
    companion_name: 'Companion name',
    'companion.vat_number': 'ID number',
    'companion.add': 'Add companion',
    urgent: 'Urgent',
    select_communication_required_attention:
      'Check when the communication requires urgent attention',
    'subtype.type': 'Type',
    'subtype.name': 'Name',
    'change-password.old-password': 'Current password',
    'change-password.new-password': 'New Password',
    'change-password.confirm-password': 'Confirm new password',
    'crm.tags.label': 'Label',
    'crm.tags.visibility': 'Visible in app',
    'crm.tags.interest': 'Interest',
    'crm.tags.helper.max-size': 'Size must be 1500 x 800 pixels',
    'user.general_data': 'General data',
    'error.signature.not_exist': 'The user does not have signature',
    'crm.users.children-list': 'Children list',
    'crm.users.not-email': 'Do not have email',
    'user.last_name': 'Last name',
    'user.select_sex': 'Select gender',
    'user.countries': 'Countries',
    'user.nationality-search': 'Search nationality',
    'user.province-search': 'Search province',
    'user.secondary_phone': 'Secondary phone',
    'user.reference-commerce': 'Reference store',
    'user.gdpr_phrase': 'I agree with the GDPR',
    'user.other_data': 'Other data',
    'user.birthdate': 'Birthdate',
    'user.document_type': 'Document type',
    'user.select_document_type': 'Select document type',
    'user.document-number': 'Document number',
    'user.visit-type': 'Visit type',
    'user.select-visit-type': 'Select visit type',
    'user.register-states': 'Register state',
    'user.marketing_data': 'Marketing',
    'user.channels': 'Channels',
    'user.tags': 'Tags',
    'user.manual-campaigns': 'Manual activities',
    'user.segmented-campaigns':
      'Automatically assigned segmented activities',
    'user.user-segments': 'User segments',
    'coupon-exchanges.title': 'Exchanged coupons list',
    'loyalty.create-stamps': 'Create stamps',
    'loyalty.validate-card': 'Validate card',
    'loyalty-card.stamps': 'Stamps',
    'receipt.select-campaigns': 'Select activity',
    'receipt.stamps': 'Stamps',
    'loyalty-cards.preview-message': 'Loyalty cards summary',
    'loyalty-cards.not-enough-stamps':
      "The client doesn't have enought stamps to redeem for a loyalty card",
    'loyalty-cards.can-not-generate-message':
      "The user doesn't have any stamps that can be redeemed.",
    'loyalty-cards.create-historic-stamps': 'Create stamps',
    'loyalty-cards.select-campaign': 'Select activity',
    'receipt.select-campaign': 'Select activity',
    'loyalty-cards.stamp-number': 'Stamp number',
    create: 'Create',
    'tickets.resume': 'Resume',
    'receipt.tickets': 'Tickets',
    'reward.create-reward': 'Create reward',
    'rewards.campaign': 'Activity',
    'rewards.select-campaigns': 'Select activity',
    rewards: 'Rewards',
    want_resend: 'Do you really want to resend the {modelName} {code}?',
    want_redeem: 'Do you really want to redeem the {modelName} {code}?',
    want_unredeem: 'Do yo really want to unredeem the {modelName} {name}',
    'receipt.create-receipt': 'Create receipt',
    'tickets.date': 'Date',
    'tickets.code': 'Code',
    'tickets.amount': 'Amount',
    'tickets.salesman': 'Salesman',
    'user.campaigns': 'Activities',
    'tickets.shop-helper-message':
      'You can only select stores that belong to the selected activities',
    shops: 'Shops',
    'tickets.shop-search.no-shops':
      'No shops found in the selected activities',
    'tickets.shop-search': 'Search store',
    'receipts.redeem-receipt': 'Redeem receipts',
    receipt: 'Receipt',
    'ticket.preview-message': 'Tickets summary',
    'tickets.not-enough-tickets':
      'The client does not have enough receipts to redeem for tickets',
    'stamps.preview-message': 'Stamps summary',
    'stamps.not-enough-stamps':
      'The client does not have enough receipts to redeem for stamps',
    'receipts.can-not-generate-message':
      'The client does not have any redeemable receipt',
    'user.actions': 'Actions',
    'actions.add': 'Add action',
    'actions.new': 'New action',
    password: 'Password',
    'forgot-password': 'Forgot password?',
    'insert-email': 'Insert your email address',
    'email.not-exist': "Email doesn't exist",
    'reset-password': 'Reset password',
    'email.recovery-sent':
      'The email to recover the password has been sent successfully',
    csv: 'CSV',
    'import-csv': 'Import CSV',
    'import-csv.delimiter-format':
      'The fields of the CSV must be delimited by a comma (,).',
    'import-csv.info': 'Information',
    'import-csv.update-warning':
      "Warning! You're going to update the data that's currently at the database. The matching is going to be done with the parameter \"id\".",
    'import-csv.select-correct-field':
      'Select the database field for every file column.',
    import: 'Import',
    'intranet.communications.resent-success':
      'The communication has been resent successfully.',
    answer: 'Answer',
    vat_number: 'ID number',
    'intranet.communicatios.resend-staff-members':
      'Select external staff members to receive the communication',
    'intranet.communications.not-contactable-staff':
      'There are not contactable staff members available in the system. Please, update at least one staff member to be contactable.',
    'staff-members': 'Users',
    confirm: 'Confirm',
    'close-month.message.pending-request':
      'There is a close month pending request for this month',
    'close-month.button.reject': 'Reject close month',
    'close-month.button.confirm': 'Confirm close month',
    'button-group.more-actions': 'More actions',
    '--': '--',
    leasing: 'Leasing',
    ecop: 'ECOP',
    'kpi-tech': 'KPI Tech',
    'date.from': 'From',
    'date.to': 'To',
    done: 'Done',
    'exit-time': 'Exit time',
    'entry-time': 'Entry time',
    'filter.search-not-found': 'Search not found',
    search: 'Search',
    'month.state': 'Month state',
    'create-new': 'Create',
    back: 'Back',
    'segments.filter-values': 'All filters must have a value',
    'segments.warning': 'Warning!',
    'segment.filters-message-first-part': 'Find customers that match',
    'segment.filters-message-second-part': 'the following conditions:',
    clear_filters: 'Clear',
    apply_filters: 'Apply filters',
    'segments.create_new': 'Create segment',
    'crm.filter.type_find': 'Type to find filters',
    'filter.type_find': 'Type to find filters',
    'filter.select': 'Select a filter',
    'no-data': 'There is no data available with the selected filters',
    select_all: 'Select all',
    clear: 'Clear',
    'confirm-modal.ok': 'Accept',
    'confirm-modal.cancel': 'Cancel',
    'dashboard.csv': 'CSV',
    refresh: 'Refresh',
    'add-file': 'Add file',
    'download-files': 'Download files',
    'informative-message.import-csv.success.message':
      'The file has been uploaded with success.',
    'informative-message.import-csv.success.title': 'Completed',
    'informative-message.import-csv.error.title': 'An error ocurred',
    'informative-message.import-csv.error':
      'The following errors have occurred',
    'informative-message.import-csv':
      'You will receive an email when the import has finished with a detailed report.',
    'import.return-to-list': 'Return to list',
    'import-csv.download-example': 'Download example',
    'import-csv.match-user-options':
      'Match imported users to the database by',
    'import-csv.update-database-warning':
      "Warning! You're going to update the data that's currently at the database.",
    'import-csv.match-by':
      'The matching is going to be done with the parameter {key}.',
    'import.format-date': 'Format date',
    'import-csv.select-date': 'Select format date',
    'import-csv.example-import': 'Draft of the imported data',
    'import-csv.preview-tables': 'Preview table',
    'crm.users.eula-confirmation.title':
      'Please, read and accept the privacy policy about data protection before importing:',
    'crm.users.eula-confirmation.first-section':
      'I declare that the data entered has been collected in strict compliance with the provisions of the applicable data protection regulations.',
    'crm.users.eula-confirmation.second-section':
      'The purpose of the database complies with current regulations.',
    'crm.users.eula-confirmation.third-section':
      'I have taken the necessary actions in order that the sending of these communications respects the regulations regarding the provision of information society services.',
    'crm.users.eula-confirmation.fourth-section':
      'I release Neuromobile from any responsibility for the improper use that may be made of this data.',
    'crm.users.eula-confirmation.check':
      'I have read and accept the privacy policy about data protection',
    'crm.users.commercial-communications-confirmation.check':
      'I confirm that these users have given their consent to receive commercial electronic communications',
    'same-period-last': 'Same period last {period}',
    'center.select_one': 'Select one centre',
    'center.not_found': 'Centres not found',
    'menu.navbar.desktop': 'Desktop',
    'menu.navbar.logout': 'Logout',
    'table-list.not_found': 'There are no elements available',
    'wizard.prev_step': 'Previous',
    'wizard.next_step': 'Next',
    'reject-close-month-modal.title': 'Close month rejection',
    'reject-close-month-modal.specify-reason':
      'Specify the reason to the store',
    reject: 'Reject',
    closed: 'Closed',
    open: 'Open',
    total_sales: 'Total sales',
    'informative-message.export-crm-csv.success.message':
      'You will receive an email with the CSV file',
    advanced_search: 'Advanced search',
    'segments.filters_needed': 'At least one of these filters is needed:',
    'segments.save_changes': 'Save changes',
    'segments.save_new': 'Save segment',
    'segments.save_as': 'Save as {modelName}',
    'segments.type_name': 'Type the {modelName} name',
    'email-campaign.warning-save-draft':
      'Before sending a test email, the campaign is going to be saved in draft state',
    'email-template.send.select_mail':
      'Type the e-mail address you want to send the test e-mail to',
    'send-email-template.help.multipleEmails':
      'Enter multiple e-mail addresses separated by commas',
    'error.email.invalid': 'Invalid e-mail address',
    'error.server': 'Server error',
    'dashboard.error.not-found-external-dashboard':
      'External dashboard not found',
    remove: 'Delete',
    warning: 'Warning',
    'yardi.warn_errors_exporting':
      'There are errors in the export. Please, review them before continue.',
    soluciona_errores: 'Soluciones errores',
    'yardi.shop_property_error': 'The store needs a property linked.',
    'yardi.tenant_code_error': 'The store needs an specific tenant code.',
    'yardi.continue_export': 'Continue export',
    'configuration.centers.go-to-centers-list': 'Go to Centers list',
    'configuration.center.new': 'New',
    'configuration.center.edit': 'Edit center',
    'configuration.center.name': 'Name',
    'configuration.center.email-from-helper':
      'account from which emails are sent',
    'configuration.center.email-from': 'Email (from)',
    'configuration.center.email-to-helper': 'Contact email account',
    'configuration.center.email-to': 'Email (to)',
    'configuration.center.latitude': 'Latitude',
    'configuration.center.longitude': 'Longitude',
    'configuration.center.app-url-helper':
      'Example of URL format: https://www.example.com/',
    'configuration.center.app-url': 'App URL',
    'configuration.center.timezone': 'Timezone',
    'configuration.center.select-timezone': 'select timezone',
    'configuration.center.language': 'Language',
    'configuration.center.default-language-helper':
      'You must first choose the available languages of the center',
    'configuration.center.default-language': 'Default language',
    'configuration.center.web-url': 'Web URL',
    'configuration.center.address': 'Address',
    'configuration.center.city': 'City',
    'configuration.center.logo-image': 'Center icon',
    'configuration.center.app-annie-configuration':
      'App annie configuration',
    'configuration.center.app-annie-android-code': 'Android app annie code',
    'configuration.center.app-annie-ios-code': 'IOS app annie code',
    'configuration.center.firebase-configuration':
      'Push communication configuration',
    'configuration.center.select-firebase': 'Select Firebase Push Project',
    'configuration.center.json-config': 'JSON config',
    'configuration.center.extra': 'Extra',
    'configuration.center.empty-languages':
      'Selecting languages is required in order to add extra information.',
    'configuration.center.blog': 'Blog',
    'configuration.center.active': 'Active',
    'configuration.center.blog.url': 'URL',
    'configuration.modules.category-search': 'Search category',
    'staff-member': 'Staff member',
    'configuration.modules.staff-search': 'Search staff',
    'configuration.center.cms': 'CMS',
    'configuration.center.tally': 'Advanced forms',
    'configuration.center.crm': 'CRM',
    'configuration.center.crm.play-center': 'Play center',
    'configuration.center.crm.tickets': 'Tickets',
    'configuration.center.crm.loyalty-cards': 'Loyalty cards',
    'configuration.center.crm.rewards': 'Rewards',
    'configuration.center.crm.pacv': 'PAC Virtual',
    'configuration.center.crm.user-required': 'User required',
    'configuration.center.footfall': 'Footfall',
    'configuration.center.footfall.username': 'Username',
    'configuration.center.footfall.siteId': 'Site id',
    'configuration.center.footfall.password': 'Password',
    'configuration.center.intranet': 'Intranet',
    'configuration.center.intranet.communications': 'Communications',
    'configuration.center.intranet.sales': 'Sales',
    'configuration.center.intranet.files': 'Files',
    'configuration.center.intranet.yardi': 'Yardi',
    'configuration.center.sovos.active': 'Sovos module',
    'configuration.center.sovos-organization-id': 'Sovos organization id',
    'configuration.center.intranet.yardi.username': 'Username',
    'configuration.center.intranet.yardi.password': 'Password',
    'configuration.center.intranet.yardi.ftpServer': 'Ftp Server',
    'configuration.center.intranet.yardi.folder': 'Folder',
    'configuration.center.intranet.yardi.add-yardi-code': 'add yardi code',
    'crm.commerce.tenant-name': 'Name',
    'configuration.center.is-default-tenant-code': 'is default yardi code',
    'configuration.center.intranet.remove-shop-property':
      'Remove yardi code',
    'configuration.center.marketing': 'Marketing',
    'configuration.center.marketing.mailing-enabled': 'Email campaigns',
    'configuration.center.multilevel': 'Multilevel',
    'configuration.center.multilevel.category-level': 'Category level',
    'configuration.center.social-wifi': 'Social wifi',
    'configuration.center.social-wifi.url': 'URL',
    'configuration.center.social-wifi.client-id': 'Client ID',
    'configuration.center.social-wifi.client-secret': 'Client secret',
    'configuration.center.social-wifi.username': 'Username',
    'configuration.center.social-wifi.password': 'Password',
    'configuration.center.social-wifi.location-id': 'Location ID',
    'configuration.center.social-wifi.channel': 'Channel',
    'configuration.center.theaters': 'Theaters',
    'configuration.center.theaters.theater-id': 'Theater ID',
    'configuration.center.theaters.api-url': 'API URL',
    'configuration.modules.store-search': 'Search store',
    'configuration.center.theaters.channel-app': 'Channel app',
    'configuration.center.theaters.channel-web': 'Channel web',
    'configuration.center.gymkhana': 'Gymkhana',
    'configuration.center.gymkhana.api-url': 'URL',
    'configuration.center.connectif': 'Connectif',
    'configuration.center.connectif.api-url': 'URL',
    'configuration.center.connectif.api-key': 'APIKEY',
    'segment.select-category': 'Select category',
    'configuration.center.operator-app': 'App operator',
    'configuration.center.playcenter': 'Playcenter',
    'configuration.center.activities': 'Activities',
    'configuration.center.sign': 'Sign',
    'configuration.center.stays': 'Stays',
    'configuration.center.sales': 'Sales',
    'configuration.center.requires-validation':
      'Sales validation is required',
    'configuration.center.general-app-data': 'General app data',
    'configuration.center.phone': 'Phone',
    'configuration.center.whatsapp-phone-format':
      'A phone number for WhatsApp should have the code area before the number. E.g. +346xxxxxxxx',
    'configuration.center.whatsapp-phone': 'Whatsapp phone',
    'configuration.center.whatsapp-message': 'Whatsapp message',
    'configuration.center.wallet-image': 'Wallet image',
    'configuration.center.home-header-image': 'App home header image',
    'configuration.center.header-size':
      'Header image size should be: 1000x760',
    'configuration.center.app-login': 'Login',
    'center.app-login.img-url': 'Image',
    'center.app-login.video-enabled': 'Enable video',
    'center.app-login.video-start-datetime': 'Video start date',
    'center.app-login.video-end-datetime': 'Video end date',
    'center.app-login.video-url': 'Video (mp4, ogg, avi)',
    'configuration.center.video-size': 'Video size should be: 1080x1920',
    'configuration.center.facebook-login': 'login with facebook',
    'configuration.center.facebook-app-id': 'App id',
    'configuration.center.facebook-app-secret': 'Secret key',
    'configuration.center.app-config.proposals': 'Proposals',
    'configuration.center.app-config.proposals-order': 'Proposals order',
    'configuration.center.app-config.privacity': 'Privacy Settings',
    'configuration.center.general-policies-url': 'General policies URL',
    'configuration.center.intranet-policies-url': 'Intranet policies URL',
    'configuration.center.play-center-policies-url':
      'Play center policies URL',
    'configuration.center.app-modules': 'Modules',
    'configuration.center.app-modules.welcome': 'Welcome',
    'configuration.center.app-modules.welcome.active': 'Active',
    'configuration.center.app-modules.featured': 'Featured',
    'configuration.center.app-modules.featured.active': 'Active',
    'configuration.center.app-modules.discount-vip': 'Discount VIP',
    'configuration.center.app-modules.services': 'Services',
    'configuration.center.app-modules.services.active': 'Active',
    'configuration.center.app-modules.cinema': 'Cinema',
    'configuration.center.app-modules.cinema.active': 'Active',
    'configuration.center.app-modules.events': 'Events',
    'configuration.center.app-modules.events.active': 'Active',
    'configuration.center.app-modules.visit-list': 'Visit list',
    'configuration.center.app-modules.visit-list.active': 'Active',
    'configuration.center.app-modules.directions': 'Directions',
    'configuration.center.app-modules.directions.active': 'Active',
    'configuration.center.app-modules.directions.url': 'URL',
    'configuration.center.app-modules.play-center': 'Play center',
    'configuration.center.app-modules.play-center.active': 'Active',
    'configuration.center.crm.tally': 'Registration advanced form',
    'configuration.center.tally.url': 'Registration form URL',
    'configuration.center.coupon-redeem-inside-building':
      'Coupon redeem inside building',
    channel: 'Channel',
    'configuration.channels.new': 'New channel',
    'configuration.channels.name': 'Name',
    dashboard: 'Dashboard',
    'configuration.dashboard.new': 'New dashboard',
    'configuration.dashboard.edit': 'Edit dashboard',
    'configuration.dashboard.name': 'Name',
    'configuration.dashboard.category': 'Category',
    'configuration.dashboard.panel': 'Panel',
    'configuration.dashboard.roles-assigned': 'Roles assigned',
    'configuration.dashboard.parameters': 'Parameters',
    'configuration.dashboard.url': 'URL',
    'configuration.dashboard.config.styles': 'Styles',
    'configuration.dashboard.config.filters': 'Filters',
    'configuration.dashboard.config.components': 'Components',
    'email-template.not_found': 'Templates not found',
    extra: 'Extra',
    'configuration.extra-attributes.new': 'New extra attributes',
    'configuration.extra-attributes.edit': 'Edit extra attributes',
    'configuration.extra-attributes.settings': 'Settings',
    'configuration.extra-attributes.select-type': 'Select Type',
    'configuration.extra-attributes.linked-model': 'Linked Model',
    'change-password.title': 'Change password',
    'password-format':
      'Please make a strong password that contains at least 10 characters, one capital letter, one lower letter and one number',
    'new-staff': 'New staff',
    'edit-staff': 'Edit staff',
    'select-level': 'Select level',
    centers: 'Centers',
    'select-center': 'Select center',
    'default-building': 'Default building',
    'select-building': 'Select building',
    'shop-search': 'Search store',
    country: 'Country',
    'select-country': 'Select country',
    Channel: 'Channel',
    'select-channel': 'Select channel',
    'dashboards-associated-to-a-user':
      'Dashboards associated with the user',
    'dashboards-associated-to-a-role':
      'Dashboards associated with the role',
    'user.can-access-bonus-request': 'Can access bonus request',
    'configuration.staff-member.notification':
      'I want to receive intranet communications by email',
    'confirm-password': 'Confirm password',
    token: 'Token',
    'extra-info': 'Extra info',
    contactable: 'Contactable',
    'job-title': 'Job title',
    'child.not-dni-error-message':
      "It isn't allowed to use the play center without entering a VAT number. Please, go to user profile to set it",
    'child.register-entry': 'Register Entry',
    'estimated-stay-time': 'Estimated stay time (HH:MM)',
    'child.checkn': 'check In',
    'child.checking-out': 'Checking out',
    'child.new': 'New child',
    'child.edit': 'Edit child',
    'child.name': 'Name',
    'child.last-name': 'Last name',
    'child.bdate': 'Birthdate',
    'child.sex': 'Gender',
    'child.select-sex': 'Select gender',
    'child.relationship': 'Relationship',
    'child.select-relationship': 'Select relationship',
    'child.notes': 'Notes',
    'crm.commerces.qr': 'Access QR',
    'commerce.new': 'New store',
    'commerce.edit': 'Edit store',
    'commerce.general-data': 'General data',
    'commerce.fiscal-name': 'Fiscal name',
    'commerce.whatsapp-phone-format':
      'A phone number for WhatsApp should have the code area before the number. E.g. +346xxxxxxxx',
    'commerce.whatsapp-phone': 'WhatsApp Phone',
    'commerce.whatsapp-message': 'WhatsApp Message',
    'commerce.web-url': 'Web URL',
    'leasing.center.attach-logo': 'Attach logo (png, jpeg, gif or jpg)',
    'cmr.commerce.disabled-sales': 'Disabled sales',
    'cmr.commerce.hide-commerce': 'Hide commerce for app and web',
    'commerce.social-networks': 'Social networks',
    instagram: 'Instagram',
    facebook: 'Facebook',
    youtube: 'Youtube',
    twitter: 'Twitter',
    linkedin: 'Linkedin',
    'commerce.personalized-target': 'Custom target',
    'commerce.categories': 'Categories',
    categories: 'Categories',
    'commerce.extra.text': 'Text extra attributes',
    'commerce.store-data': 'Store data',
    'crm.commerces.warn-disabled-sales':
      "Sales are disabled for this store. Please, enable it before setting a manager or it won't be able to manage the store's sales.",
    'crm.commerces.company-reference-number': 'Shop code',
    'contract-start-date': 'Contract start date',
    'contract-end-date': 'Contract end date',
    'shop-property': 'Store Property',
    'yardi-code': 'Yardi code',
    'intellisales-exception': 'IntelliSales exception shop',
    'saft-exception.info':
      'Check this box only if the store declares sales using more than one concept',
    'commerce.capacity-data': 'Capacity data',
    surface: 'Surface (m2)',
    rent: 'Rent',
    capacity: 'Capacity',
    threshold_active_check_in: 'Threshold active wait (min)',
    'commerce.address': 'Address',
    'commerce.latitude': 'Latitude',
    'commerce.longitude': 'Longitude',
    'commerce.retailCountry': 'Country',
    'crm.receipt': 'Receipt',
    'receipts.modal-validate-title': 'Validate receipt',
    'receipts.reject-reason': 'Rejection reason',
    'receipts.description': 'Rejection description',
    'receipts.reject': 'Reject',
    'receipts.validate': 'Validate',
    'receipts.save': 'Save',
    segmentCategory: 'Segment category',
    'segment-category.new': 'New segment category',
    'segment-category.edit': 'Edit segment category',
    'crm.segment.new': 'New segment',
    'crm.segment.edit': 'Edit segment',
    'segment.general_data': 'General data',
    'segment.automatic-actions': 'Automatic actions',
    'crm.segments.userType': 'Tag',
    'crm.segments.automatic-action-type': 'Dynamic / Static',
    'crm.segments.select_automatic-action-type': 'Select',
    'crm.segments.automati-action-type.explanation':
      'When choosing dynamic or static label, it will be removed or kept if the user leaves the segment',
    'crm.segments.add-new-automatic-actionType': 'Add automatic action',
    'crm.segments.add-new-user-type': 'Create tag',
    'segment.filters': 'Filters',
    user: 'User',
    'crm.tag': 'Tag',
    associated_users:
      'This tag has been linked with {numberOfUsers} users.',
    'crm.tag.edit': 'Edit tag',
    'user.import.bulk-edit-accordeon': 'Bulk edit all imported users',
    'crm.user.remove-email':
      'Send an email to contact notifying that has been remove of the system',
    'crm.users.go-to-users-list': 'Go to Users list',
    'users.not-has-email-warning-message':
      'It is recommended to add the following fields',
    'users.warning': 'Warning',
    'users.new': 'New customer',
    'users.edit': 'Edit user',
    want_save_without_gdpr_or_electronic_communications:
      'The following notification checks are not still check, do you want to continue?',
    'dashboard.index.no-home-dashboard':
      'Welcome to Neuromobile Back Office',
    'bonus-request': 'Bonus request',
    'bonusRequest.new': 'New bonus request',
    'bonusRequest.edit': 'Edit bonus request',
    'bonusRequest.shops': 'Brand',
    'bonusRequest.shops-search': 'Search a brand',
    'bonusRequest.surface': 'Surface(m2)',
    'bonusRequest.month-label': 'Period from January to...',
    'bonusRequest.select-month': 'Select month',
    'bonusRequests.exercise': 'Exercise Accounting Period',
    'bonusRequest.select-exercise': 'Select year',
    'bonusRequest.franchisee': 'Franchisee',
    'bonusRequest.bonus-percentage': 'Rent discount request (%)',
    'bonusRequest.bonus-period': 'Rent discount duration (months)',
    'bonusRequest.costs-data': 'Costs data',
    'bonusRequest.sales': 'Sales',
    'bonusRequest.sales-tooltip': 'Exercise sales (e.g. 2019)',
    'bonusRequest.sale-cost': 'Sale cost',
    'bonusRequest.employee-cost': 'Employee cost per year',
    'bonusRequest.employee-hours-year': 'Employee hours per year',
    'bonusRequest.supplies': 'Supplies',
    'bonusRequest.depreciation': 'Depreciation',
    'bonusRequest.franchisee-expenses': 'Franchisee expenses',
    'bonusRequest.other-expenses': 'Other expenses',
    'bonusRequest.confirm-bonus-request':
      'I confirm the bonus request is valid.',
    'communication.new': 'New communication',
    files: 'Files',
    'files.add-folder': 'Add folder',
    'directory.new': 'New directory',
    'directory.edit': 'Edit directory',
    'directory.name': 'Name',
    'file.edit': 'Edit file',
    file: 'File',
    'file.roles_with_access': 'Roles with access',
    'file.description-optional': 'Optional description',
    'file.shop_with_access': 'Shops with access',
    yardi: 'Yardi',
    'intranet.flow.total-flows': 'Total month',
    'sales-year.new': 'New annual sales statement',
    'intranet.sales-year.warn-pending-review':
      "The admin is reviewing the annual sales statement and it can't be changed right now.",
    'intranet.sales-year.disclaimer':
      'Certificate of net sales (without VAT) made in the reference store during the indicated period of time, which accurately match the supporting documents and accounting notes in the company records. Net sales (VAT not included)',
    'intranet-sales-years.reject-reason': 'Reject reason',
    'sales-years.confirm-shop-sales-years':
      'I confirm the annual sales statement is valid.',
    'intranet.sales-years.enable-sales-button':
      'Enable annual sales statement {year}',
    'intranet.sales-years.disable-sales-button':
      'Disable annual sales statement {year}',
    want_continue: 'Do you really want to continue?',
    'intranet.sales-years.enable-sales-years-confirmation':
      'Upon confirming, the annual sales declaration process will begin. Stores may create their annual sales statement and may modify their sales for the year {year}.',
    'intranet.sales-years.invalid-sales':
      'The annual sales statement cannot start because the following stores have months without validation:',
    'intranet.sales-years.disable-sales-years-confirmation': [
      'Upon confirming, the annual sales declaration process will be finished. ',
      'After this, stores will not be able to modify their annual sales statement and their sales for the year {year}.',
    ],
    'sales-year.show': 'Show annual sales statement',
    'intranet.sales-years.reject-sale': 'Reject',
    'intranet.sales-years.validate-sale': 'Validate',
    'intranet.update_dmi-message':
      "You're going to update {shop}'s DMI value of {month}.",
    'intranet.sales-years.update-yardi':
      'I want to upload the Yardi file (it may take a few minutes)',
    Update: 'Update',
    'intranet-sales-years.reject-annual-statement':
      'Reject annual sales statement',
    'title.header.intranet-sale': 'Sales',
    'intranet.sales.declare-yearly-sales': 'Declare annual sales statement',
    'intranet.sales.set-estimated-sales': 'Declare estimated sale',
    'intranet.sales.reopen-month': 'Reopen the month',
    'intranet.sale.request-to-reopen-month':
      'Request the reopening of the month',
    'sales.total-sales': 'Total sales',
    'sales.total-estimated-sales': 'Total estimated sales',
    'sovos-sales.total-sales': 'IntelliSales total',
    'sales.total-receipts': 'Total receipts',
    'sales.average-receipt-value': 'Average receipt value',
    'sales.average-receipt-message':
      'Calculated on the days and stores when sales and number of tickets have been entered',
    'sales.csv': 'CSV',
    'sales.xlsx': 'Xlsx',
    'sale.new': 'New sale',
    'sales.tickets-number': 'Receipts number',
    'intranet.sales.import-sales': 'Import Sales',
    'sales.xlsx-format': 'The XLSX should have the following format:',
    'sales.info': 'Info',
    'sales.xlsx.columns':
      'It should have three columns, with the date on the first column, sale concept in the second column and amount on the third. In the first row it should be the name of the column.',
    'sales.xlsx.date-format':
      "The date should be set in Excel with type 'date'.",
    'sales.xlsx.concept-format':
      "The concept is identified by its Yardi code. By default, this value should be 'gm'.",
    'sales.xlsx.amount-format':
      "The amount should be set in Excel with type 'currency'.",
    'sales.xlsx.download-example': 'Download Template',
    'sales.import-xlsx': 'Import XLSX',
    'intranet.sales.import': 'Import',
    'intranet.sales.download-sales': 'Download Sales',
    'intranet.sales.select-dates': 'Select date range',
    'intranet.sales.download-csv': 'Download CSV',
    'intranet.sales.reopen-month-warning':
      'Are you sure to reopen the month?',
    'intranet.sales.request-to-reopen-month-modal':
      'To request the reopening of the month, contact the center manager',
    'select-shop': 'Select store',
    'intranet.sales.declare-estimated-sale': 'Declare estimated sale for ',
    'intranet.sales.and-date': 'and date',
    'intranet.sales.estimated-sale.amount': 'Amount',
    'intranet.sales.estimated-sale.for-whole-year':
      'Declare same amount for whole year',
    'select-state': 'Select state',
    'subtype.edit': 'Edit subtype',
    'title.communication.subtypes': 'Communications subtypes',
    subtype: 'Subtype',
    'subtype.new': 'New subtype',
    'appointment-url': 'Appointment URL',
    'marketing.campaign.active-crm': 'Active on CRM',
    'marketing.campaign.share-campaign': 'Share activity',
    'campaign.compare-data-against': 'Compare activity data against',
    'campaign.comparison.helper':
      'Activity KPI will be compared against collected data in the selected period.',
    'campaign.comparison': 'Comparison',
    'dmr-weekly.select-dates': 'Select range dates',
    'marketing.campaign.select-campaign': 'Select activity',
    'marketing.campaign.search-campaign': 'Search activity',
    'marketing.campaign.legal-bases': 'Legal bases',
    'marketing.campaign.legal-bases.url': 'URL',
    'marketing.campaign.legal-bases.text': 'Text',
    'marketing.campaign.submit-image': 'Submit image',
    'marketing.campaign.attach-image':
      'Attach image (png, jpeg, gif or jpg)',
    'marketing.campaign.raffle.block-raffle-info':
      'Loyalty settings should not be changed after the first receipt has been redeemed. Keep this in mind when saving and launching this activity.',
    'marketing.campaign.loyalty.general_data': 'General data',
    loyalty: 'Loyalty',
    'marketing.campaign.loyalty.amount-per-stamp': 'Amount per stamp',
    'marketing.campaign.loyalty.amount': 'Amount',
    stamps: 'Stamps',
    'marketing.campaign.loyalty.welcome-stamps': 'Welcome stamps',
    'marketing.campaign.loyalty.welcome-tickets': 'Welcome tickets',
    'marketing.campaign.reward.valid-days': 'Valid days',
    'marketing.campaign.reward.valid-until': 'Valid until',
    'marketing.campaign.loyalty.receipts-redemption-days':
      'Receipts redemption days',
    'marketing.campaign.raffle.loyalty-code': 'Loyalty code',
    'marketing.campaign.without-remains': 'Without remains',
    'marketing.campaign.generic-reward': 'Generic Reward',
    'campaign.targeted-segment': 'Target segment',
    'campaign.segment.helper':
      'The selected segment will associate target users to the activity. Do not use this selector to register participants in the activity.',
    'campaign.segment.list': 'Segment list',
    'marketing.campaign.reward.other-options': 'Other options',
    'marketing.campaign.loyalty-campaign': 'Loyalty card activity',
    'marketing.campaign.generate-all-reward': 'Generate all rewards',
    'marketing.campaign.loyalty-card.other-options': 'Other options',
    'marketing.campaign.next-tier-loyalty-campaign':
      'Next tier loyalty card activity',
    'marketing.campaign.rules': 'Store exceptions',
    'title.header.marketing.campaignRules': 'Raffle exceptions',
    'campaign-rule.new': 'New activity rule',
    'campaign-rule.label': 'Label',
    'campaign-rule.ticket-amount': 'Ticket amount',
    'marketing.campaign.loyalty.aditional-segmented-participations':
      'Segmented aditional participations',
    'title.header.marketing.segmented-campaign-rules':
      'Segmented activity rules',
    'segmented-campaign-rule.new': 'New segmented activity rule',
    'segmented-campaign-rule.aditional-participations':
      'Aditional participations',
    want_stop: 'Do you really want to stop the {modelName} {name}?',
    'email-campaign.segment.list': 'Segments',
    'email-campaign.campaign.list': 'Activities',
    'email-campaign.htmlBody.params': 'E-mail params',
    'email-campaign.params.subject.user_name': 'Add the user name',
    'email-campaign.params.subject.user_email': 'Add the user e-mail',
    'email-campaign.params.subject.user_code': 'Add the user code',
    'email-campaign.params.subject.center_name': 'Add the centre name',
    'email-campaign.params.help.subject':
      'Insert this codes to add vars in e-mail subject',
    'email-campaign.frequency-sending': 'Sending frequency',
    'email-campaign.sending-date': 'Sending date',
    'segment.warning_update_users':
      'The campaign will be daily sent to new users that match the segment conditions',
    'email-campaign.warning-unsub-link':
      "Warning! The current template doesn't have an unsubscribe link. Please, add one before publishing the campaign.",
    'email-campaign.htmlBody.file_import': 'Import template',
    'email-campaign.htmlBody.design': 'Design your e-mail',
    'email-campaign.htmlBody.preview': 'Preview',
    'email-campaign.params.html.user_name': 'Add the user name',
    'email-campaign.params.html.user_email': 'Add the user e-mail',
    'email-campaign.params.html.center_name': 'Add the centre name',
    'email-campaign.params.html.unsub-link': 'Add the unsubscribe link',
    'email-campaign.params.subject.qr_image': 'Add the qr image. Example: ',
    'email-campaign.params.subject.barcode_image':
      'Add the barcode image. Example: ',
    'email-campaign.params.subject.reward-code': 'Add the reward code',
    'email-campaign.params.subject.activity-legal': 'Add legal bases ',
    'email-campaign.params.help.html':
      'Insert this codes to add vars in HTML template',
    'email-campaign.segment': 'Segment',
    'email-campaign.date': 'Date',
    'emailing.campaign.import-template': 'Import template',
    'campaign.close': 'Close activity',
    'campaign-action': 'Action',
    'marketing.campaign.closing-notes': 'Notes',
    'marketing.campaign.files': 'Activity images',
    'marketing.start-date-tooltip':
      'The activity date range includes both the events during the activity and the previous actions.',
    'marketing.campaign.date-helper':
      'Remember to review the actions dates if you change the date range of the activity',
    'marketing.campaign.max-capacity': 'Maximum capacity',
    'marketing.campaign.raffle.general_data': 'General data',
    'crm.campaign.loyalty-campaign-info-message':
      'Selecting a loyalty card activity will generate a reward when loyalty card is achieved',
    'crm.campaign.generate-all-reward': 'Generate all rewards',
    'crm.campaign.generate-all-rewards-message':
      'Checking generate all rewards will generate as many rewards as loyalty cards can be obtained',
    'title.header.marketing.campaignActions': 'Activity actions',
    'campaign-action.new': 'New campaign action',
    'title.header.marketing.campaignGoals': 'Activity goals',
    'campaign-goal.new': 'New activity goal',
    'campaign-goal.kpi': 'Kpi',
    'campaign-goal.value': 'Value',
    'marketing.kpi.manual-kpis': 'Manual KPIs',
    'marketing.campaigns.goals': 'Activity goals',
    'marketing.campaigns.goal-chart': 'Goal kpi follow-up',
    'marketing.report': 'Report',
    'lineitem.new': 'New line item',
    'lineitem.edit': 'Edit line item',
    'service.new': 'New service',
    'service.edit': 'Edit service',
    'supplier.new': 'New supplier',
    'supplier.edit': 'Edit supplier',
    'vat-number': 'Vat number',
    services: 'Services',
    comments: 'Comments',
    'suppliers.active': 'Active',
    'zip-code': 'Zip code',
    web: 'Web',
    'title.header.marketing.suppliercontacts': 'Supplier contacts',
    'supplier-contact.new': 'New supplier contact',
    'update-password.server-problem':
      'There was a problem with the server. Please, contact with support team',
    'invalid-token': 'The token is invalid',
    'communication.roles.commerce': 'Shop',
    'communication.roles.management': 'Management',
    'communication.roles.services': 'Services',
    'communication.state.pending': 'Pending',
    'communication.state.accepted': 'Accepted',
    'communication.state.rejected': 'Rejected',
    'communication.state.solved': 'Solved',
    not_between: 'not between',
    contains: 'contains',
    not_contains: 'not contains',
    is: 'is',
    is_not: 'is not',
    more_than_or_equal: 'More than or equal',
    less_than_or_equal: 'Less than or equal',
    'email-template.type.send_user_registration_email':
      'Customer registration message',
    'email-template.type.send_user_reset_password_email':
      'Mobile app user password reset message',
    'email-template.type.send_user_activate_email':
      'Mobile app user activation message',
    'email-template.type.send_user_child_birthday_reminder':
      "Customer's child birthday reminder",
    'email-template.type.send_reprint_tickets':
      'Tickets reprint notification',
    'email-template.type.send_proposals_report': 'Coupons report',
    'email-template.type.send_user_child_birthday_visit_ack':
      "Customer's child after birthday visit acknowledgement",
    'email-template.type.send_contact': 'Message from mobile app',
    'email-template.type.send_intranet_communication_email':
      'Intranet communication notification',
    'email-template.type.send_staff_connection_warning':
      'Warning staff connection',
    'email-template.type.send_staff_reset_password_email':
      "Reset staff's password reminder",
    'email-template.type.send_user_unsubscribe_email':
      'Unsubscribe concact email ',
    'menu.navbar.analytics': 'Analytics',
    'menu.navbar.cms': 'CMS',
    'menu.navbar.proposal.show': 'Proposals',
    'menu.navbar.push-notification.index': 'Push notifications',
    'menu.navbar.category.show': 'Categories',
    'menu.navbar.cms.mof': 'MOF',
    'menu.navbar.crm': 'CRM',
    'menu.navbar.customer.show': 'Customer list',
    'menu.navbar.segment.show': 'Segments',
    'menu.navbar.segment-categories.show': 'Segment categories',
    'menu.navbar.receipt.show': 'Verify receipts',
    'menu.navbar.tag.show': 'Tags',
    'menu.navbar.playcenter.show': 'Play center',
    'menu.navbar.shop.show': 'Shops list',
    'menu.navbar.marketing': 'Marketing',
    'menu.navbar.crm.activities.index': 'Activities',
    'menu.navbar.marketing.campaign.index': 'Activities',
    'menu.navbar.marketing.emailing.index': 'Email campaigns',
    'menu.navbar.marketing.supplier.index': 'Suppliers',
    'menu.navbar.marketing.lineitem.index': 'Line items',
    'menu.navbar.marketing.service.index': 'Services',
    'menu.navbar.intranet': 'Intranet',
    'menu.navbar.intranet.communication.index': 'Communications',
    'menu.navbar.intranet.subtype.index': 'Communication subtypes',
    'menu.navbar.intranet.sale.index': 'Sales',
    'menu.navbar.intranet.saft': 'IntelliSales',
    'menu.navbar.intranet.file.index': 'Files',
    'menu.navbar.bonus-requests': 'Bonus requests',
    'menu.navbar.footfall': 'Footfall',
    'menu.navbar.sales-years': 'Annual sales statement',
    'menu.navbar.configuration': 'Configuration',
    'menu.navbar.staff.show': 'Users list',
    'menu.navbar.configuration.extra': 'Extra attributes',
    'menu.navbar.configuration.password': 'Change password',
    'menu.navbar.channel.show': 'Channels',
    'menu.navbar.email-template.show': 'E-mail templates',
    'menu.navbar.configuration.dashboards': 'Dashboards',
    'menu.navbar.configuration.centers': 'Centers',
    'menu.navbar.configuration.my-center': 'My center',
    'staff.level.commerce': 'Shop',
    'staff.level.manager': 'Manager',
    'staff.level.owner': 'Owner',
    'staff.level.admin': 'Admin',
    'staff.level.crmmanager': 'CRM Manager',
    'staff.level.crmoperator': 'CRM Operator',
    'staff.level.storeManager': 'Store Manager',
    'staff.level.weboperator': 'Agency',
    'staff.level.scmanager': 'SC Manager',
    user_types: 'User types',
    days_before: 'days before',
    single_family: 'Single family',
    select_month: 'Select a month',
    select_year: 'Select a year',
    save_segment: 'Save segment',
    'segments.save_as_segment': 'Save as segment',
  },
];
